import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Keycloak from 'keycloak-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private router = inject(Router);
  private keycloak: Keycloak;

  constructor() {
    // Initialisierung der Keycloak-Instanz
    this.keycloak = new Keycloak({
      url: 'https://keycloak.herkules.starke-dms.cloud',
      realm: 'herkulesdev',
      clientId: environment.clientId,
    });
  }

  /**
   * Initializes the Keycloak instance with specific configuration options.
   *
   * @return {Promise<boolean>} A promise that resolves to `true` if the initialization is successful, or `false` if an error occurs during initialization.
   */
  async initialize(): Promise<boolean> {
    try {
      return await this.keycloak.init({
        checkLoginIframe: false,
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/public/assets/silent-check-sso.html',
        redirectUri: window.location.origin + '/welcome',
      });
    } catch (error) {
      console.error('Keycloak initialization failed:', error);
      return false;
    }
  }

  getKeycloakInstance(): Keycloak | null {
    return this.keycloak || null;
  }

  /**
   * Initiates the login process using Keycloak and redirects the user upon successful login.
   * It appends the current router URL to the redirect URI for post-login navigation continuity.
   *
   * @return {Promise<void>} A promise that resolves when the login*/
  async login(): Promise<void> {
    try {
      await this.keycloak.login({
        redirectUri: window.location.origin + this.router.url,
      });
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  }

  /**
   * Logs the user out of the Keycloak session and clears the token from local storage.
   * Redirects the user to the specified welcome page after successful logout.
   *
   * @return {Promise<void>} A promise that resolves when the logout operation is completed successfully.
   * @throws {Error*/
  async logout(): Promise<void> {
    try {
      await this.keycloak.logout({
        redirectUri: window.location.origin + '/welcome',
      });
      // Token aus dem lokalen Speicher entfernen
      this.keycloak.clearToken();
    } catch (error) {
      console.error('Logout failed:', error);
      throw error;
    }
  }

  /**
   * Checks if the user is currently logged in by validating and updating the session token.
   *
   * @return {Promise<boolean>} A promise that resolves to true if the user is authenticated, otherwise false.
   */
  async isLoggedIn(): Promise<boolean> {
    try {
      await this.keycloak.updateToken(5);
      return this.keycloak.authenticated ?? false;
    } catch {
      return false;
    }
  }

  /**
   * Retrieves the current authentication token by ensuring the token is refreshed.
   *
   * @return {Promise<string>} A promise that resolves with the current authentication token as a string. If the token is unavailable, it returns an empty string.
   * @throws {Error} Throws an error if unable to retrieve the token.
   */
  async getToken(): Promise<string> {
    try {
      await this.refreshToken();
      return this.keycloak.token ?? '';
    } catch (error) {
      console.error('Failed to get token:', error);
      throw error;
    }
  }

  /**
   * Refreshes the authentication token by invoking the Keycloak SDK's updateToken method.
   * Attempts to update the token within a 30-second window. If the token refresh fails,
   * the method logs the error and returns false.
   *
   * @return {Promise<boolean>} A promise*/
  async refreshToken(): Promise<boolean> {
    try {
      return await this.keycloak.updateToken(30);
    } catch (error) {
      console.error('Token refresh failed:', error);
      return false;
    }
  }

  /**
   * Retrieves all user roles, combining both realm and client/resource roles, and removes duplicates.
   *
   * @return {string[]} An array of unique roles assigned to the user.
   */
  getUserRoles(): string[] {
    const realmRoles = this.keycloak.realmAccess?.roles ?? [];

    const resourceAccess = this.keycloak.resourceAccess ?? {};
    const clientRoles = Object.values(resourceAccess).flatMap(resource => resource.roles ?? []);

    return [...new Set([...realmRoles, ...clientRoles])];
  }

  /**
   * Checks if the user has the specified role.
   *
   * @param {string} role - The role to check against the user's roles.
   * @return {boolean} Returns true if the user has the specified role, otherwise false.
   */
  hasRole(role: string): boolean {
    const userRoles = this.getUserRoles();
    return userRoles.includes(role);
  }

  /**
   * Checks if the user has any of the specified roles.
   *
   * @param {string[]} roles - An array of role names to check against the user's roles.
   * @return {boolean} Returns true if the user has at least one role from the provided array, or false otherwise.
   */
  hasAnyRole(roles: string[]): boolean {
    const userRoles = this.getUserRoles();
    return roles.some(role => userRoles.includes(role));
  }

  /**
   * Retrieves the roles assigned to a specific client in the Keycloak resource access.
   *
   * @param {string} clientId - The unique identifier of the client whose roles are being retrieved.
   * @return {string[]} An array of role names associated with the specified client. If no roles are found, an empty array is returned.
   */
  getClientRoles(clientId: string): string[] {
    return this.keycloak.resourceAccess?.[clientId]?.roles ?? [];
  }

  /**
   * Fetches the user profile using the Keycloak client.
   *
   * This method utilizes Keycloak's `loadUserProfile` function to retrieve
   * detailed information about the currently authenticated user's profile.
   *
   * @return {Promise<Object>} A promise that resolves to the user profile object
   * containing user details or rejects with an error if fetching the profile fails.
   * @throws Will throw an error if unable to load the user profile.
   */
  async getUserProfile() {
    try {
      return await this.keycloak.loadUserProfile();
    } catch (error) {
      console.error('Failed to load user profile:', error);
      throw error;
    }
  }

  /**
   * Sets up the token refresh process by assigning a callback to be executed
   * when the token expires. It ensures that the refreshToken method is triggered
   * and the provided onRefresh callback is executed upon a successful token refresh.
   *
   * @param {Function} onRefresh - A callback function to be executed when the
   * token is successfully refreshed.
   * @return {void} Does not return a value.
   */
  setupTokenRefresh(onRefresh: () => void): void {
    this.keycloak.onTokenExpired = () => {
      void (async () => {
        try {
          const refreshed = await this.refreshToken();
          if (refreshed) {
            onRefresh();
          }
        } catch (error) {
          console.error('Token refresh callback failed:', error);
        }
      })();
    };
  }
}
