import { Component, input } from '@angular/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgClass } from '@angular/common';

@Component({
    selector: 'hk-button',
    imports: [ButtonModule, SpinnerComponent, NgClass],
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss'
})
export class ButtonComponent {
  icon = input<string>('');
  text = input<string>('');
  disabled = input<boolean>(false);
  theme = input<'default' | 'alternate'>('default');
  width = input<string | number>('');
  isIcon = input<boolean>(false);
  loading = input<boolean>(false);
}
