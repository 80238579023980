/**
 * @fileoverview Toast component that provides notification functionality using
 * Syncfusion's toast module with customizable position, message, and type.
 */

import {AfterViewInit, Component, input, viewChild} from '@angular/core';
import {ToastComponent as EJToast, ToastModule, ToastPositionModel,} from '@syncfusion/ej2-angular-notifications';

/**
 * @component ToastComponent
 * @description A standalone component that displays toast notifications.
 * Uses Syncfusion's toast module for displaying notifications with configurable
 * position, message, title, and type.
 *
 * @implements {AfterViewInit}
 */
@Component({
    selector: 'hk-toast',
    imports: [ToastModule],
    templateUrl: './toast.component.html',
    styleUrl: './toast.component.scss'
})
export class ToastComponent implements AfterViewInit {
  readonly element = viewChild.required<EJToast>('element');
  message = input<string>('');
  title = input<string>('');
  type = input<'Info' | 'Warning' | 'Error' | 'Success'>('Error');
  protected position: ToastPositionModel = {
    X: 'Right',
    Y: 'Top',
  };

  /**
   * Lifecycle hook that is called after view initialization
   * Shows the toast notification after a brief timeout
   *
   * @implements AfterViewInit
   * @returns {void}
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      const element = this.element();
      if (element) {
        element.show();
      }
    });
    return;
  }
}
