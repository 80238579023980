<ejs-dialog (close)="onClose()" [isModal]="true" [showCloseIcon]="true" height="80%" width="50%">
  <ng-template #header> {{ 'AppSettings.Title' | translate }}</ng-template>
  <ng-template #content class="app-setting-content">
    <ejs-tab>
      <e-tabitems>
        <e-tabitem>
          <ng-template #headerText>
            <div>
              {{ 'AppSettings.Language.Title' | translate }}
            </div>
          </ng-template>
          <ng-template #content class="item-content">
            <hk-language-settings></hk-language-settings>
          </ng-template>
        </e-tabitem>
        <e-tabitem>
          <ng-template #headerText>
            <div>
              {{ 'AppSettings.Security.Title' | translate }}
            </div>
          </ng-template>
          <ng-template #content class="item-content">
            <hk-security-settings></hk-security-settings>
          </ng-template>
        </e-tabitem>
      </e-tabitems>
    </ejs-tab>
  </ng-template>
</ejs-dialog>
