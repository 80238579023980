<button
  [class]="theme()"
  [disabled]="disabled()"
  [iconCss]="!loading() && icon() !== '' ? 'e-icons ' + icon() : ''"
  [ngClass]="{
    'is-icon': isIcon(),
  }"
  [style.width]="width()"
  ejs-button
  style="height: 40px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"
>
  @if (!loading()) {
    <span class="button-text">{{ text() }}</span>
  } @else {
    <hk-spinner style="position: relative; height: 18px" class="button-spinner" [color]="'white'" />
  }
</button>
