{
  "main": {
    "de-CH": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "de",
        "territory": "CH"
      },
      "dates": {
        "calendars": {
          "gregorian": {
            "months": {
              "format": {
                "abbreviated": {
                  "1": "Jan.",
                  "2": "Feb.",
                  "3": "März",
                  "4": "Apr.",
                  "5": "Mai",
                  "6": "Juni",
                  "7": "Juli",
                  "8": "Aug.",
                  "9": "Sept.",
                  "10": "Okt.",
                  "11": "Nov.",
                  "12": "Dez."
                },
                "narrow": {
                  "1": "J",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "A",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "Januar",
                  "2": "Februar",
                  "3": "März",
                  "4": "April",
                  "5": "Mai",
                  "6": "Juni",
                  "7": "Juli",
                  "8": "August",
                  "9": "September",
                  "10": "Oktober",
                  "11": "November",
                  "12": "Dezember"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "Jan",
                  "2": "Feb",
                  "3": "Mär",
                  "4": "Apr",
                  "5": "Mai",
                  "6": "Jun",
                  "7": "Jul",
                  "8": "Aug",
                  "9": "Sep",
                  "10": "Okt",
                  "11": "Nov",
                  "12": "Dez"
                },
                "narrow": {
                  "1": "J",
                  "2": "F",
                  "3": "M",
                  "4": "A",
                  "5": "M",
                  "6": "J",
                  "7": "J",
                  "8": "A",
                  "9": "S",
                  "10": "O",
                  "11": "N",
                  "12": "D"
                },
                "wide": {
                  "1": "Januar",
                  "2": "Februar",
                  "3": "März",
                  "4": "April",
                  "5": "Mai",
                  "6": "Juni",
                  "7": "Juli",
                  "8": "August",
                  "9": "September",
                  "10": "Oktober",
                  "11": "November",
                  "12": "Dezember"
                }
              }
            },
            "days": {
              "format": {
                "abbreviated": {
                  "sun": "So.",
                  "mon": "Mo.",
                  "tue": "Di.",
                  "wed": "Mi.",
                  "thu": "Do.",
                  "fri": "Fr.",
                  "sat": "Sa."
                },
                "narrow": {
                  "sun": "S",
                  "mon": "M",
                  "tue": "D",
                  "wed": "M",
                  "thu": "D",
                  "fri": "F",
                  "sat": "S"
                },
                "short": {
                  "sun": "So",
                  "mon": "Mo",
                  "tue": "Di",
                  "wed": "Mi",
                  "thu": "Do",
                  "fri": "Fr",
                  "sat": "Sa"
                },
                "wide": {
                  "sun": "Sonntag",
                  "mon": "Montag",
                  "tue": "Dienstag",
                  "wed": "Mittwoch",
                  "thu": "Donnerstag",
                  "fri": "Freitag",
                  "sat": "Samstag"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "sun": "So",
                  "mon": "Mo",
                  "tue": "Di",
                  "wed": "Mi",
                  "thu": "Do",
                  "fri": "Fr",
                  "sat": "Sa"
                },
                "narrow": {
                  "sun": "S",
                  "mon": "M",
                  "tue": "D",
                  "wed": "M",
                  "thu": "D",
                  "fri": "F",
                  "sat": "S"
                },
                "short": {
                  "sun": "So.",
                  "mon": "Mo.",
                  "tue": "Di.",
                  "wed": "Mi.",
                  "thu": "Do.",
                  "fri": "Fr.",
                  "sat": "Sa."
                },
                "wide": {
                  "sun": "Sonntag",
                  "mon": "Montag",
                  "tue": "Dienstag",
                  "wed": "Mittwoch",
                  "thu": "Donnerstag",
                  "fri": "Freitag",
                  "sat": "Samstag"
                }
              }
            },
            "quarters": {
              "format": {
                "abbreviated": {
                  "1": "Q1",
                  "2": "Q2",
                  "3": "Q3",
                  "4": "Q4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1. Quartal",
                  "2": "2. Quartal",
                  "3": "3. Quartal",
                  "4": "4. Quartal"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "1": "Q1",
                  "2": "Q2",
                  "3": "Q3",
                  "4": "Q4"
                },
                "narrow": {
                  "1": "1",
                  "2": "2",
                  "3": "3",
                  "4": "4"
                },
                "wide": {
                  "1": "1. Quartal",
                  "2": "2. Quartal",
                  "3": "3. Quartal",
                  "4": "4. Quartal"
                }
              }
            },
            "dayPeriods": {
              "format": {
                "abbreviated": {
                  "midnight": "Mitternacht",
                  "am": "AM",
                  "pm": "PM",
                  "morning1": "morgens",
                  "morning2": "vorm.",
                  "afternoon1": "mittags",
                  "afternoon2": "nachm.",
                  "evening1": "abends",
                  "night1": "nachts"
                },
                "narrow": {
                  "midnight": "Mitternacht",
                  "am": "AM",
                  "pm": "PM",
                  "morning1": "morgens",
                  "morning2": "vorm.",
                  "afternoon1": "mittags",
                  "afternoon2": "nachm.",
                  "evening1": "abends",
                  "night1": "nachts"
                },
                "wide": {
                  "midnight": "Mitternacht",
                  "am": "AM",
                  "pm": "PM",
                  "morning1": "morgens",
                  "morning2": "vormittags",
                  "afternoon1": "mittags",
                  "afternoon2": "nachmittags",
                  "evening1": "abends",
                  "night1": "nachts"
                }
              },
              "stand-alone": {
                "abbreviated": {
                  "midnight": "Mitternacht",
                  "am": "AM",
                  "pm": "PM",
                  "morning1": "Morgen",
                  "morning2": "Vorm.",
                  "afternoon1": "Mittag",
                  "afternoon2": "Nachm.",
                  "evening1": "Abend",
                  "night1": "Nacht"
                },
                "narrow": {
                  "midnight": "Mitternacht",
                  "am": "AM",
                  "pm": "PM",
                  "morning1": "Morgen",
                  "morning2": "Vorm.",
                  "afternoon1": "Mittag",
                  "afternoon2": "Nachm.",
                  "evening1": "Abend",
                  "night1": "Nacht"
                },
                "wide": {
                  "midnight": "Mitternacht",
                  "am": "AM",
                  "pm": "PM",
                  "morning1": "Morgen",
                  "morning2": "Vormittag",
                  "afternoon1": "Mittag",
                  "afternoon2": "Nachmittag",
                  "evening1": "Abend",
                  "night1": "Nacht"
                }
              }
            },
            "eras": {
              "eraNames": {
                "0": "v. Chr.",
                "0-alt-variant": "vor unserer Zeitrechnung",
                "1": "n. Chr.",
                "1-alt-variant": "unserer Zeitrechnung"
              },
              "eraAbbr": {
                "0": "v. Chr.",
                "0-alt-variant": "v. u. Z.",
                "1": "n. Chr.",
                "1-alt-variant": "u. Z."
              },
              "eraNarrow": {
                "0": "v. Chr.",
                "0-alt-variant": "v. u. Z.",
                "1": "n. Chr.",
                "1-alt-variant": "u. Z."
              }
            },
            "dateFormats": {
              "full": "EEEE, d. MMMM y",
              "long": "d. MMMM y",
              "medium": "dd.MM.y",
              "short": "dd.MM.yy"
            },
            "timeFormats": {
              "full": "HH:mm:ss zzzz",
              "long": "HH:mm:ss z",
              "medium": "HH:mm:ss",
              "short": "HH:mm"
            },
            "dateTimeFormats": {
              "full": "{1} 'um' {0}",
              "long": "{1} 'um' {0}",
              "medium": "{1}, {0}",
              "short": "{1}, {0}",
              "availableFormats": {
                "Bh": "h B",
                "Bhm": "h:mm B",
                "Bhms": "h:mm:ss B",
                "d": "d",
                "E": "ccc",
                "EBhm": "E h:mm B",
                "EBhms": "E h:mm:ss B",
                "Ed": "E, d.",
                "Ehm": "E h:mm a",
                "EHm": "E, HH:mm",
                "Ehms": "E, h:mm:ss a",
                "EHms": "E, HH:mm:ss",
                "Gy": "y G",
                "GyMMM": "MMM y G",
                "GyMMMd": "d. MMM y G",
                "GyMMMEd": "E, d. MMM y G",
                "h": "h 'Uhr' a",
                "H": "HH 'Uhr'",
                "hm": "h:mm a",
                "Hm": "HH:mm",
                "hms": "h:mm:ss a",
                "Hms": "HH:mm:ss",
                "hmsv": "h:mm:ss a v",
                "Hmsv": "HH:mm:ss v",
                "hmv": "h:mm a v",
                "Hmv": "HH:mm v",
                "M": "L",
                "Md": "d.M.",
                "MEd": "E, d.M.",
                "MMd": "d.MM.",
                "MMdd": "dd.MM.",
                "MMM": "LLL",
                "MMMd": "d. MMM",
                "MMMEd": "E, d. MMM",
                "MMMMd": "d. MMMM",
                "MMMMEd": "E, d. MMMM",
                "MMMMW-count-one": "'Woche' W 'im' MMMM",
                "MMMMW-count-other": "'Woche' W 'im' MMMM",
                "ms": "mm:ss",
                "y": "y",
                "yM": "M.y",
                "yMd": "d.M.y",
                "yMEd": "E, d.M.y",
                "yMM": "MM.y",
                "yMMdd": "dd.MM.y",
                "yMMM": "MMM y",
                "yMMMd": "d. MMM y",
                "yMMMEd": "E, d. MMM y",
                "yMMMM": "MMMM y",
                "yQQQ": "QQQ y",
                "yQQQQ": "QQQQ y",
                "yw-count-one": "'Woche' w 'des' 'Jahres' Y",
                "yw-count-other": "'Woche' w 'des' 'Jahres' Y"
              },
              "appendItems": {
                "Day": "{0} ({2}: {1})",
                "Day-Of-Week": "{0} {1}",
                "Era": "{1} {0}",
                "Hour": "{0} ({2}: {1})",
                "Minute": "{0} ({2}: {1})",
                "Month": "{0} ({2}: {1})",
                "Quarter": "{0} ({2}: {1})",
                "Second": "{0} ({2}: {1})",
                "Timezone": "{0} {1}",
                "Week": "{0} ({2}: {1})",
                "Year": "{1} {0}"
              },
              "intervalFormats": {
                "intervalFormatFallback": "{0} – {1}",
                "Bh": {
                  "B": "h 'Uhr' B – h 'Uhr' B",
                  "h": "h–h 'Uhr' B"
                },
                "Bhm": {
                  "B": "h:mm 'Uhr' B – h:mm 'Uhr' B",
                  "h": "h:mm – h:mm 'Uhr' B",
                  "m": "h:mm – h:mm 'Uhr' B"
                },
                "d": {
                  "d": "d.–d."
                },
                "Gy": {
                  "G": "y G – y G",
                  "y": "y–y G"
                },
                "GyM": {
                  "G": "MM.y GGGGG – MM.y GGGGG",
                  "M": "MM.y – MM.y GGGGG",
                  "y": "MM.y – MM.y GGGGG"
                },
                "GyMd": {
                  "d": "dd.–dd.MM.y GGGGG",
                  "G": "dd.MM.y GGGGG – dd.MM.y GGGGG",
                  "M": "dd.MM. – dd.MM.y GGGGG",
                  "y": "dd.MM.y – dd.MM.y GGGGG"
                },
                "GyMEd": {
                  "d": "E, dd.MM.y – E, dd.MM.y GGGGG",
                  "G": "E, dd.MM.y GGGGG – E, dd.MM.y GGGGG",
                  "M": "E, dd.MM. – E, dd.MM.y GGGGG",
                  "y": "E, dd.MM.y – E, dd.MM.y GGGGG"
                },
                "GyMMM": {
                  "G": "MMM y G – MMM y G",
                  "M": "MMM–MMM y G",
                  "y": "MMM y – MMM y G"
                },
                "GyMMMd": {
                  "d": "d.–d. MMM y G",
                  "G": "d. MMM y G – d. MMM y G",
                  "M": "d. MMM – d. MMM y G",
                  "y": "d. MMM y – d. MMM y G"
                },
                "GyMMMEd": {
                  "d": "E, d. – E, d. MMM y G",
                  "G": "E, d. MMM y G – E E, d. MMM y G",
                  "M": "E, d. MMM – E, d. MMM y G",
                  "y": "E, d. MMM y – E, d. MMM y G"
                },
                "h": {
                  "a": "h 'Uhr' a – h 'Uhr' a",
                  "h": "h – h 'Uhr' a"
                },
                "H": {
                  "H": "HH–HH 'Uhr'"
                },
                "hm": {
                  "a": "h:mm a – h:mm a",
                  "h": "h:mm–h:mm a",
                  "m": "h:mm–h:mm a"
                },
                "Hm": {
                  "H": "HH:mm–HH:mm 'Uhr'",
                  "m": "HH:mm–HH:mm 'Uhr'"
                },
                "hmv": {
                  "a": "h:mm a – h:mm a v",
                  "h": "h:mm–h:mm a v",
                  "m": "h:mm–h:mm a v"
                },
                "Hmv": {
                  "H": "HH:mm–HH:mm 'Uhr' v",
                  "m": "HH:mm–HH:mm 'Uhr' v"
                },
                "hv": {
                  "a": "h a – h a v",
                  "h": "h–h a v"
                },
                "Hv": {
                  "H": "HH–HH 'Uhr' v"
                },
                "M": {
                  "M": "M.–M."
                },
                "Md": {
                  "d": "dd.–dd.MM.",
                  "M": "dd.MM. – dd.MM."
                },
                "MEd": {
                  "d": "E, dd. – E, dd.MM.",
                  "M": "E, dd.MM. – E, dd.MM."
                },
                "MMM": {
                  "M": "MMM–MMM"
                },
                "MMMd": {
                  "d": "d.–d. MMM",
                  "M": "d. MMM – d. MMM"
                },
                "MMMEd": {
                  "d": "E, d. – E, d. MMM",
                  "M": "E, d. MMM – E, d. MMM"
                },
                "MMMM": {
                  "M": "LLLL–LLLL"
                },
                "y": {
                  "y": "y–y"
                },
                "yM": {
                  "M": "MM.y – MM.y",
                  "y": "MM.y – MM.y"
                },
                "yMd": {
                  "d": "dd.–dd.MM.y",
                  "M": "dd.MM. – dd.MM.y",
                  "y": "dd.MM.y – dd.MM.y"
                },
                "yMEd": {
                  "d": "E, dd. – E, dd.MM.y",
                  "M": "E, dd.MM. – E, dd.MM.y",
                  "y": "E, dd.MM.y – E, dd.MM.y"
                },
                "yMMM": {
                  "M": "MMM–MMM y",
                  "y": "MMM y – MMM y"
                },
                "yMMMd": {
                  "d": "d.–d. MMM y",
                  "M": "d. MMM – d. MMM y",
                  "y": "d. MMM y – d. MMM y"
                },
                "yMMMEd": {
                  "d": "E, d. – E, d. MMM y",
                  "M": "E, d. MMM – E, d. MMM y",
                  "y": "E, d. MMM y – E, d. MMM y"
                },
                "yMMMM": {
                  "M": "MMMM–MMMM y",
                  "y": "MMMM y – MMMM y"
                }
              }
            }
          }
        }
      }
    }
  }
}
