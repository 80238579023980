import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from, lastValueFrom } from 'rxjs';
import { AuthService } from '../services/security/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private excludedUrls = ['/assets', '/public/assets'];

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isUrlExcluded(request.url)) {
      return next.handle(request);
    }

    return from(this.handleRequest(request, next));
  }

  /**
   * Handles an HTTP request by intercepting it and adding an Authorization header if a valid token is retrieved.
   *
   * @param {HttpRequest<unknown>} request - The incoming HTTP request to be processed.
   * @param {HttpHandler} next - The next handler in the HTTP request chain.
   * @return {Promise<HttpEvent<unknown>>} A promise resolving to the HTTP event resulting from processing the request.
   */
  private async handleRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Promise<HttpEvent<unknown>> {
    const token = await this.authService.getToken();

    if (!token) {
      await this.authService.logout();
      throw new Error('No authentication token available');
    }

    const authRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });

    try {
      return await lastValueFrom(next.handle(authRequest));
    } catch (httpError: unknown) {
      if ((httpError as { status: number })?.status === 401) {
        await this.authService.logout();
      }
      throw httpError;
    }
  }

  /**
   * Determines if the given URL is excluded based on a predefined list of excluded URLs.
   *
   * @param {string} url - The URL to be checked against the excluded URLs list.
   * @return {boolean} - Returns true if the URL is found in the excluded URLs list, otherwise false.
   */
  private isUrlExcluded(url: string): boolean {
    return this.excludedUrls.some(excludedUrl => url.includes(excludedUrl));
  }
}
