import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { UserStore } from '../store/user/user.store';
import { MasterDataStore } from '../store/master-data/masterData.store';
import { AuthService } from '../services/security/auth.service';

export const AuthGuard: CanActivateFn = async () => {
  const authService = inject(AuthService);
  const userStore = inject(UserStore);
  const masterDataStore = inject(MasterDataStore);

  try {
    const isLoggedIn = await authService.isLoggedIn();

    if (!isLoggedIn) {
      await authService.login();
      return false;
    }

    // Load user profile
    const userProfile = await authService.getUserProfile();
    userStore.setUser(userProfile);

    // Check admin role
    const roles = authService.getUserRoles();
    if (roles.includes('orga_admin')) {
      userStore.setAdmin(true);
    }

    // Load master data if not initialized
    if (!masterDataStore.isInitialized()) {
      await masterDataStore.loadMasterData();
    }

    return true;
  } catch (error) {
    console.error('Error in auth guard:', error);
    await authService.login();
    return false;
  }
};
