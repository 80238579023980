import { inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';
import { TranslationService } from '../services/translation/translation.service';

type RequestBody = Record<string, unknown>;

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private toastService = inject(ToastService);
  private translationService = inject(TranslationService);

  intercept(
    req: HttpRequest<RequestBody>,
    next: HttpHandler,
  ): Observable<HttpEvent<RequestBody | HttpResponse<RequestBody>>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (this.isHandledStatusCode(error.status)) {
          void this.handleError(error.status);
        }
        return throwError(() => new Error(this.formatErrorMessage(error)));
      }),
    );
  }

  private async handleError(statusCode: number): Promise<void> {
    this.toastService.show(
      'Error',
      this.translationService.translate(`Interceptor.${statusCode}.Title`),
      this.translationService.translate(`Interceptor.${statusCode}.Detail`),
    );
  }

  private formatErrorMessage(error: HttpErrorResponse): string {
    return error?.message || error.message || 'An unknown error occurred';
  }

  // check if status code is handled, so no cryptic toasts can occur
  private isHandledStatusCode(statusCode: number): boolean {
    const handledCodes = [400, 401, 403, 404, 408, 429, 500, 502, 503, 504];
    return handledCodes.includes(statusCode);
  }
}
