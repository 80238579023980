import { Injectable, signal } from '@angular/core';
import { loadCldr, setCulture } from '@syncfusion/ej2-base';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
// Deutsche CLDR-Daten
import * as gregoriande from 'cldr-data/main/de/ca-gregorian.json';
import * as numbersde from 'cldr-data/main/de/numbers.json';
import * as timeZoneNamesde from 'cldr-data/main/de/timeZoneNames.json';

// Englische CLDR-Daten (GB)
import * as gregorianengb from 'cldr-data/main/en/ca-gregorian.json';
import * as numbersengb from 'cldr-data/main/en/numbers.json';
import * as timeZoneNamesengb from 'cldr-data/main/en/timeZoneNames.json';

// Englische CLDR-Daten (US)
import * as gregorianenu from 'cldr-data/main/en-US-POSIX/ca-gregorian.json';
import * as numbersenu from 'cldr-data/main/en-US-POSIX/numbers.json';
import * as timeZoneNamesenu from 'cldr-data/main/en-US-POSIX/timeZoneNames.json';

// Schweizer CLDR-Daten
import * as gregorianch from 'cldr-data/main/de-CH/ca-gregorian.json';
import * as numbersch from 'cldr-data/main/de-CH/numbers.json';
import * as timeZoneNamesch from 'cldr-data/main/de-CH/timeZoneNames.json';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';

type SupportedLocaleKeys = 'en' | 'en-US' | 'de' | 'de-CH';

@Injectable({ providedIn: 'root' })
export class GlobalizationService {
  public static readonly STORAGE_VALUE = 'locale';
  readonly currentLocale = signal<SupportedLocaleKeys>('de');
  currentLocaleSignal = this.currentLocale.asReadonly();

  supportedLocales: Record<string, string>[] = [
    {
      value: 'en',
      text: 'English (GB)',
    },
    {
      value: 'en-US',
      text: 'English (USA)',
    },
    {
      value: 'de',
      text: 'Deutsch (Deutschland)',
    },
  ];
  private formats = {
    en: {
      date: {
        short: 'dd/MM/yyyy', // 16/12/2024
        medium: 'MMM dd, yyyy', // Dec 16, 2024
        long: 'MMMM dd, yyyy', // December 16, 2024
      },
      time: {
        short: 'hh:mm a', // 09:30 AM
        long: 'HH:mm:ss', // 21:30:45
      },
      dateTime: {
        short: 'dd/MM/yyyy hh:mm a', // 12/16/2024 09:30 AM
        medium: 'MMM dd, yyyy hh:mm a', // Dec 16, 2024 09:30 AM
        long: 'MMMM dd, yyyy hh:mm:ss a', // December 16, 2024 09:30:45 AM
      },
      number: {
        integer: '#,##0', // 1000 -> 1,000
        float: '#,##0.###', // 1000.12 -> 1,000.12
        long: '#,##0', // 1000 -> 1,000
        currency: '#,##0.00', // 1000.12 -> 1,000.12
      },
    },

    'en-US': {
      date: {
        short: 'MM/dd/yyyy', // 12/16/2024
        medium: 'MMM d, yyyy', // Dec 16, 2024 (note: single 'd' for no leading zero)
        long: 'MMMM d, yyyy', // December 16, 2024
      },
      time: {
        short: 'h:mm a', // 9:30 AM (note: single 'h' for no leading zero)
        long: 'HH:mm:ss', // 21:30:45
      },
      dateTime: {
        short: 'MM/dd/yyyy, h:mm a', // 12/16/2024, 9:30 AM
        medium: 'MMM d, yyyy, h:mm a', // Dec 16, 2024, 9:30 AM
        long: 'MMMM d, yyyy, h:mm:ss a', // December 16, 2024, 9:30:45 AM
      },
      number: {
        integer: '#,##0', // 1000 -> 1,000
        float: '#,##0.##', // 1000.12 -> 1,000.12
        long: '#,##0', // 1000 -> 1,000
        currency: '#,##0.00', // 1000.12 -> $1,000.12
      },
    },
    de: {
      date: {
        short: 'dd.MM.yyyy', // 16.12.2024
        medium: 'dd. MMM yyyy', // 16. Dez 2024
        long: 'dd. MMMM yyyy', // 16. Dezember 2024
      },
      time: {
        short: 'HH:mm', // 21:30
        long: 'HH:mm:ss', // 21:30:45
      },
      dateTime: {
        short: 'dd.MM.yyyy HH:mm', // 16.12.2024 21:30
        medium: 'dd. MMM yyyy HH:mm', // 16. Dez 2024 21:30
        long: 'dd. MMMM yyyy HH:mm:ss', // 16. Dezember 2024 21:30:45
      },
      number: {
        integer: '#.##0', // 1000 -> 1.000
        float: '#.##0,###', // 1000.12 -> 1.000,12
        long: '#.##0', // 1000 -> 1.000
        currency: '#.##0,00', // 1000.12 -> 1.000,00
      },
    },
    'de-CH': {
      date: {
        short: 'dd.MM.yyyy', // 16.12.2024
        medium: 'dd. MMM yyyy', // 16. Dez 2024
        long: 'dd. MMMM yyyy', // 16. Dezember 2024
      },
      time: {
        short: 'HH:mm', // 21:30
        long: 'HH:mm:ss', // 21:30:45
      },
      dateTime: {
        short: 'dd.MM.yyyy HH:mm', // 16.12.2024 21:30
        medium: 'dd. MMM yyyy HH:mm', // 16. Dez 2024 21:30
        long: 'dd. MMMM yyyy HH:mm:ss', // 16. Dezember 2024 21:30:45
      },
      number: {
        integer: "#'##0", // 1000 -> 1'000
        float: "#'##0.###", // 1000.12 -> 1'000.12
        long: "#'##0", // 1000 -> 1'000
        currency: "#'##0.00", // 1000.12 -> 1'000.12
      },
    },
    //TODO ADD ISO and NL formats
  };

  constructor() {
    // Initialisiere mit gespeicherter oder Default Locale
    const savedLocale = window.localStorage.getItem(
      GlobalizationService.STORAGE_VALUE,
    ) as SupportedLocaleKeys;
    if (savedLocale) {
      void this.setCurrentLocale(savedLocale);
    }
  }

  async setCurrentLocale(locale: SupportedLocaleKeys) {
    this.currentLocale.set(locale);
    window.localStorage.setItem(GlobalizationService.STORAGE_VALUE, locale);

    // Update Syncfusion CLDR
    this.updateSyncfusion();

    return;
  }

  updateSyncfusion() {
    const locale = this.currentLocale();

    // Basierend auf der Locale die entsprechenden CLDR-Daten hinzufügen
    switch (locale) {
      case 'de':
        loadCldr(numberingSystems, gregoriande, numbersde, timeZoneNamesde);
        setCulture('de');
        registerLocaleData(localeDe);
        break;
      case 'en':
        loadCldr(numberingSystems, numbersengb, timeZoneNamesengb, gregorianengb);
        setCulture('en');
        registerLocaleData(localeEn);
        break;
      case 'en-US':
        loadCldr(numberingSystems, numbersenu, timeZoneNamesenu, gregorianenu);
        setCulture('en');
        registerLocaleData(localeEn);
        break;
      case 'de-CH':
        loadCldr(numberingSystems, numbersch, timeZoneNamesch, gregorianch);
        setCulture('de');
        registerLocaleData(localeDe);
        break;
      default:
        loadCldr(numberingSystems, gregoriande, numbersde, timeZoneNamesde);
        setCulture('de');
        registerLocaleData(localeDe);
    }
  }

  getLocaleDateFormat(form: 'short' | 'medium' | 'long'): string {
    return this.formats[this.currentLocale()].date[form];
  }

  getLocaleDateTimeFormat(form: 'short' | 'medium' | 'long'): string {
    return this.formats[this.currentLocale()].dateTime[form];
  }

  getLocaleTimeFormat(form: 'short' | 'long'): string {
    return this.formats[this.currentLocale()].dateTime[form];
  }

  getLocaleNumberFormat(form: 'integer' | 'float' | 'long' | 'currency'): string {
    return this.formats[this.currentLocale()].number[form];
  }
}
