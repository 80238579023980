<ejs-dropdownlist
  #languageSelector
  (change)="onLanguageChange($event)"
  [dataSource]="languageList()"
  [fields]="fields"
  [placeholder]="'AppSettings.Language.DisplayLanguage' | translate"
  [value]="currentLanguage"
  floatLabelType="Auto"
/>

@if (languageUpdated) {
  <ejs-dropdownlist
    (change)="onLocalizationChange($event)"
    [dataSource]="filteredLocales"
    [fields]="fields"
    [placeholder]="'AppSettings.Language.Localization' | translate"
    [value]="selectedLocale"
    floatLabelType="Auto"
  />
}
