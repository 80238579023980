import { computed, Injectable, inject, signal } from '@angular/core';
import { Year } from '../../interfaces/indices/year.interface';
import { BaseDoctype } from '../../interfaces/mask/docType.interface';
import { YearService } from '../../services/master-data/years.service';
import { DoctypeService } from '../../services/master-data/doctype.service';
import { AuditTypeService } from '../../services/master-data/audittype.service';
import { AuditType } from '../../interfaces/audit/audit.interface';
import { combineLatest, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MasterDataStore {
  private yearService = inject(YearService);
  private doctypeService = inject(DoctypeService);
  private auditService = inject(AuditTypeService);

  // State Signals
  private docTypesSignal = signal<BaseDoctype[]>([]);
  // Public Computed Values
  public docTypes = computed(() => this.docTypesSignal());
  // Computed Getters
  public getDoctypeName = computed(() => (id: string) => {
    const types = this.docTypesSignal();
    const doctype = types.find(x => x.id === id);
    return doctype?.name ?? id;
  });
  private yearsSignal = signal<Year[]>([]);
  public years = computed(() => this.yearsSignal());
  public hasData = computed(
    () => this.docTypesSignal().length > 0 && this.yearsSignal().length > 0,
  );
  public getYearName = computed(() => (id: string) => {
    const types = this.yearsSignal();
    const year = types.find(x => x.id === id);
    return year?.key ?? id;
  });
  private auditTypesSignal = signal<AuditType[]>([]);
  public auditTypes = computed(() => this.auditTypesSignal());
  private loadingSignal = signal<boolean>(true);
  public isLoading = computed(() => this.loadingSignal());
  private initializedSignal = signal<boolean>(false);
  public isInitialized = computed(() => this.initializedSignal());

  // Methods
  public async loadMasterData(): Promise<void> {
    if (this.initializedSignal()) {
      return;
    }

    this.loadingSignal.set(true);

    try {
      const [docTypes, years, auditTypes] = await firstValueFrom(
        combineLatest([
          this.doctypeService.getDoctypes(),
          this.yearService.getYears(),
          this.auditService.getAuditTypes(),
        ]),
      );

      this.docTypesSignal.set(docTypes);
      this.yearsSignal.set(years);
      this.auditTypesSignal.set(auditTypes);
      this.initializedSignal.set(true);
    } catch (error) {
      console.error('Error loading master data:', error);
    } finally {
      this.loadingSignal.set(false);
    }
  }

  public updateYears(years: Year[]) {
    this.yearsSignal.set(years);
  }

  public clearMasterData() {
    this.docTypesSignal.set([]);
    this.yearsSignal.set([]);
    this.auditTypesSignal.set([]);
    this.loadingSignal.set(false);
    this.initializedSignal.set(false);
  }
}
