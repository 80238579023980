import { Injectable, inject } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PasswordChangeService {
  private authService = inject(AuthService);

  async redirectToPasswordChange(): Promise<void> {
    const keycloak = this.authService.getKeycloakInstance();

    if (!keycloak) {
      console.error('Keycloak instance not initialized');
      return;
    }

    const baseUrl = keycloak.authServerUrl;
    const realm = keycloak.realm;
    const clientId = keycloak.clientId;
    const redirectUri = window.location.origin;

    const url = new URL(`${baseUrl}/realms/${realm}/protocol/openid-connect/auth`);

    // Setze die Query-Parameter
    const params = {
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: 'code',
      scope: 'openid',
      kc_action: 'UPDATE_PASSWORD',
    };

    Object.entries(params).forEach(([key, value]) => {
      url.searchParams.append(key, value!);
    });

    // Führe die Weiterleitung durch
    window.location.href = url.toString();
  }
}
