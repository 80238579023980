import { Injectable, computed, signal } from '@angular/core';
import { KeycloakProfile } from 'keycloak-js';

@Injectable({
  providedIn: 'root',
})
export class UserStore {
  // Private Signals für den internen State
  private userSignal = signal<KeycloakProfile | null>(null);
  // Public Getters als Computed Signals
  public user = computed(() => this.userSignal());
  // Computed property für den vollständigen Namen
  public fullName = computed(() => {
    const user = this.userSignal();
    if (!user) return '';

    // Kombiniere Vor- und Nachname, falls vorhanden
    const nameParts = [user.firstName, user.lastName]
      .filter(Boolean) // Entfernt null/undefined/empty strings
      .join(' ')
      .trim();

    // Wenn kombinierter Name existiert, verwende diesen, sonst username
    return nameParts || user.username || '';
  });
  // Optional: Zusätzliche hilfreiche Methoden
  public hasUser = computed(() => this.userSignal() !== null);
  public getUserId = computed(() => this.userSignal()?.id || '');
  public getUsername = computed(() => this.userSignal()?.username || '');
  private isAdminSignal = signal<boolean>(false);
  public isAdmin = computed(() => this.isAdminSignal());

  // State Update Methods
  public setUser(user: KeycloakProfile): void {
    this.userSignal.set(user);
  }

  public clearUser(): void {
    this.userSignal.set(null);
  }

  public setAdmin(isAdmin: boolean): void {
    this.isAdminSignal.set(isAdmin);
  }
}
