import { Injectable, signal, computed } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AdminStore {
  // Private Signal für den State
  private adminActiveSignal = signal<boolean>(false);

  // Public Computed für den Zugriff von außen
  public adminActive = computed(() => this.adminActiveSignal());

  // Methods
  public activateAdministration(): void {
    this.adminActiveSignal.set(true);
  }

  public deactivateAdministration(): void {
    this.adminActiveSignal.set(false);
  }

  // Optional: Toggle-Methode als Erweiterung
  public toggleAdministration(): void {
    this.adminActiveSignal.update(current => !current);
  }
}
