import { Component, computed, effect, inject, OnInit, Signal, ViewChild } from '@angular/core';
import {
  DropDownListComponent,
  DropDownListModule,
  ListBoxModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { TranslationService } from '../../../services/translation/translation.service';
import { TranslatePipe } from '../../../pipes/translation/translation.pipe';
import { GlobalizationService } from '../../../services/translation/globalization.service';

type SupportedLocaleKeys = 'en' | 'en-US' | 'de' | 'de-CH';

@Component({
  selector: 'hk-language-settings',
  imports: [ListBoxModule, DropDownListModule, TranslatePipe],
  templateUrl: './language-settings.component.html',
  styleUrl: './language-settings.component.scss',
})
export class LanguageSettingsComponent implements OnInit {
  @ViewChild('languageSelector') languageSelector?: DropDownListComponent;
  translationService = inject(TranslationService);
  globalizationService = inject(GlobalizationService);
  languageList: Signal<Record<string, string>[]> = computed(() => [
    { value: 'en', text: this.translationService.translate('Languages.English') },
    { value: 'de', text: this.translationService.translate('Languages.German') },
  ]);
  currentLanguage = this.translationService.currentLanguage();
  selectedLocale = this.globalizationService.currentLocale();
  public fields = { text: 'text', value: 'value' };
  supportedLocales = this.globalizationService.supportedLocales;
  languageUpdated = true;
  filteredLocales: Record<string, string>[] = [];

  constructor() {
    //effects to update language and globalization
    effect(() => {
      const _ = this.languageList();
      if (this.languageSelector && _) {
        //needs virtual timeout to fix possible timing issues
        setTimeout(() => {
          this.languageSelector!.refresh();
        }, 0);
      }
    });
  }

  /**
   * Handles the event when the language is changed, updates the application's locale and related settings.
   *
   * @param {Object} $event - The event object containing the selected language data.
   * @param {Object} $event.itemData - The data associated with the selected language.
   * @param {string} $event.itemData.value - The key or value of the selected language.
   * @return {Promise<void>} A promise that resolves when the language change process is complete.
   */
  async onLanguageChange($event: { itemData: { value: string } }): Promise<void> {
    this.languageUpdated = false;
    const newLang = $event.itemData.value;
    await this.translationService.loadTranslations(newLang);
    await this.globalizationService.setCurrentLocale(newLang as SupportedLocaleKeys);
    this.filteredLocales = this.supportedLocales.filter(locale =>
      locale['value'].startsWith(newLang),
    );
    this.selectedLocale = newLang as SupportedLocaleKeys;
    this.languageUpdated = true;
  }

  /**
   * Method initializes the component state. This includes setting the language and locale
   * based on saved values in local storage, updating the translation and globalization services
   * if needed, and filtering supported locales based on the current language.
   *
   * @return {void} Does not return a value.
   */
  ngOnInit(): void {
    const savedLang = window.localStorage.getItem(TranslationService.STORAGE_VALUE) || 'de';
    const savedLocal = (window.localStorage.getItem(GlobalizationService.STORAGE_VALUE) ||
      'de') as SupportedLocaleKeys;
    if (savedLang !== this.translationService.currentLanguage()) {
      void this.translationService.loadTranslations(savedLang);
    }
    if (savedLocal !== this.globalizationService.currentLocale()) {
      void this.globalizationService.setCurrentLocale(savedLocal);
    }
    this.filteredLocales = this.supportedLocales.filter(locale =>
      locale['value'].startsWith(this.currentLanguage),
    );
  }

  /**
   * Handles the localization change event by updating the current locale
   * using the provided localization value from the event data.
   *
   * @param {Object} $event - The event object containing localization data.
   * @param {Object} $event.itemData - The data related to the selected localization.
   * @param {string} $event.itemData.value - The localization key for the selected locale.
   * @return {void} This method does not return any value.
   */
  onLocalizationChange($event: { itemData: { value: string } }): void {
    void this.globalizationService.setCurrentLocale($event.itemData.value as SupportedLocaleKeys);
  }
}
