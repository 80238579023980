<ejs-toolbar overflowMode="Popup">
  <e-items>
    @if (router.url !== '/welcome' && router.url !== '/') {
      <e-item prefixIcon="e-icons e-menu" (click)="toggleNavbar()"></e-item>
    }
    <e-item>
      <ng-template #template
        ><h1 class="app-title">Herkules {{ adminActive() ? 'Administration' : '' }}</h1>
      </ng-template>
    </e-item>
    <e-item align="Right">
      <ng-template #template>
        <ejs-menu
          (select)="onThemeSelect($event)"
          [items]="themeMenuItems()"
          cssClass="header-menu"
        ></ejs-menu>
      </ng-template>
    </e-item>
    <e-item align="Right">
      <ng-template #template>
        <ejs-menu
          (select)="onLanguageSelect($event.item.id)"
          [items]="languageMenuItems()"
          cssClass="header-menu"
        >
        </ejs-menu>
      </ng-template>
    </e-item>
  </e-items>
</ejs-toolbar>
