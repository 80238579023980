import { Component, computed, effect, inject, output, signal } from '@angular/core';
import { MenuItemModel, MenuModule, ToolbarAllModule } from '@syncfusion/ej2-angular-navigations';
import { Theme, ThemeService } from '../../services/theme/theme.service';
import { Router } from '@angular/router';
import { TranslationService } from '../../services/translation/translation.service';
import { AdminStore } from '../../store/app/administration.store';
import { GlobalizationService } from '../../services/translation/globalization.service';

@Component({
  selector: 'hk-header',
  imports: [MenuModule, ToolbarAllModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  toggleSidebar = output<void>();
  router = new Router();
  themeService = inject(ThemeService);
  translationService = inject(TranslationService);
  globalizationService = inject(GlobalizationService);
  adminStore = inject(AdminStore);

  currentLanguage = computed(() => this.translationService.currentLanguage());
  adminActive = this.adminStore.adminActive;
  activeFlag = signal('de');
  languageMenuItems = computed(() => [
    {
      text: '',
      iconCss: `flag-icon flag-icon-${this.activeFlag()}`,
      items: [
        {
          text: this.translationService.translate('Languages.German'),
          id: 'de-de',
          iconCss: 'flag-icon flag-icon-de',
        },
        {
          text: this.translationService.translate('Languages.English'),
          id: 'en-gb',
          iconCss: 'flag-icon flag-icon-gb',
        },
      ],
    },
  ]);

  themeMenuItems = computed(() => [
    {
      text: '',
      iconCss: 'e-icons e-paint-bucket',
      items: [
        { text: this.translationService.translate('Menu.Theme.Light'), id: Theme.Light },
        { text: this.translationService.translate('Menu.Theme.Dark'), id: Theme.Dark },
        { text: this.translationService.translate('Menu.Theme.System'), id: Theme.System },
      ],
    },
  ]);

  constructor() {
    effect(
      () => {
        const lang = this.currentLanguage();
        switch (lang) {
          case 'de': {
            this.activeFlag.set('de');
            break;
          }
          case 'en': {
            this.activeFlag.set('gb');
            break;
          }
        }
      },
      { allowSignalWrites: true },
    );
  }

  /**
   * Handles the selection of a language based on the provided language ID.
   *
   * @param {string} id - The language identifier in the format 'language-region',
   *                      such as 'en-gb', 'de-de', or an empty string.
   * @return {void} No value is returned from this method.
   */
  onLanguageSelect(id: string): void {
    if (id === 'en-gb' || id === 'de-de' || id === '') {
      const language = id.split('-')[0];
      const flag = id.slice(-2);
      void this.translationService.loadTranslations(language);
      void this.globalizationService.setCurrentLocale(language as 'en' | 'de');
      this.activeFlag.set(flag);
    }
  }

  /**
   * Toggles the state of the navigation bar by emitting a sidebar toggle event.
   *
   * @return {void} Does not return any value.
   */
  toggleNavbar(): void {
    this.toggleSidebar.emit();
  }

  /**
   * Handles theme selection from a menu and sets the application's theme accordingly.
   *
   * @param {Object} args - The arguments passed to the method.
   * @param {MenuItemModel} args.item - The selected menu item containing the theme ID.
   * @return {void} Does not return a value.
   */
  onThemeSelect(args: { item: MenuItemModel }): void {
    if (
      args.item.id === Theme.System ||
      args.item.id === Theme.Light ||
      args.item.id === Theme.Dark
    ) {
      this.themeService.setTheme(args.item.id as Theme);
    }
  }
}
