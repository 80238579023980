import { Component, output } from '@angular/core';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TranslatePipe } from '../../pipes/translation/translation.pipe';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { LanguageSettingsComponent } from './language-settings/language-settings.component';
import { SecuritySettingsComponent } from './security-settings/security-settings.component';

@Component({
    selector: 'hk-app-settings',
    imports: [
        DialogModule,
        TranslatePipe,
        TabModule,
        LanguageSettingsComponent,
        SecuritySettingsComponent,
    ],
    templateUrl: './app-settings.component.html',
    styleUrl: './app-settings.component.scss'
})
export class AppSettingsComponent {
  close = output();

  onClose() {
    this.close.emit();
  }
}
