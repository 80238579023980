{
  "main": {
    "en-US-POSIX": {
      "identity": {
        "version": {
          "_cldrVersion": "36"
        },
        "language": "en",
        "territory": "US",
        "variant": "POSIX"
      },
      "dates": {
        "timeZoneNames": {
          "hourFormat": "+HH:mm;-HH:mm",
          "gmtFormat": "GMT{0}",
          "gmtZeroFormat": "GMT",
          "regionFormat": "{0} Time",
          "regionFormat-type-daylight": "{0} Daylight Time",
          "regionFormat-type-standard": "{0} Standard Time",
          "fallbackFormat": "{1} ({0})",
          "zone": {
            "America": {
              "Adak": {
                "exemplarCity": "Adak"
              },
              "Anchorage": {
                "exemplarCity": "Anchorage"
              },
              "Anguilla": {
                "exemplarCity": "Anguilla"
              },
              "Antigua": {
                "exemplarCity": "Antigua"
              },
              "Araguaina": {
                "exemplarCity": "Araguaina"
              },
              "Argentina": {
                "Rio_Gallegos": {
                  "exemplarCity": "Rio Gallegos"
                },
                "San_Juan": {
                  "exemplarCity": "San Juan"
                },
                "Ushuaia": {
                  "exemplarCity": "Ushuaia"
                },
                "La_Rioja": {
                  "exemplarCity": "La Rioja"
                },
                "San_Luis": {
                  "exemplarCity": "San Luis"
                },
                "Salta": {
                  "exemplarCity": "Salta"
                },
                "Tucuman": {
                  "exemplarCity": "Tucuman"
                }
              },
              "Aruba": {
                "exemplarCity": "Aruba"
              },
              "Asuncion": {
                "exemplarCity": "Asunción"
              },
              "Bahia": {
                "exemplarCity": "Bahia"
              },
              "Bahia_Banderas": {
                "exemplarCity": "Bahia Banderas"
              },
              "Barbados": {
                "exemplarCity": "Barbados"
              },
              "Belem": {
                "exemplarCity": "Belem"
              },
              "Belize": {
                "exemplarCity": "Belize"
              },
              "Blanc-Sablon": {
                "exemplarCity": "Blanc-Sablon"
              },
              "Boa_Vista": {
                "exemplarCity": "Boa Vista"
              },
              "Bogota": {
                "exemplarCity": "Bogota"
              },
              "Boise": {
                "exemplarCity": "Boise"
              },
              "Buenos_Aires": {
                "exemplarCity": "Buenos Aires"
              },
              "Cambridge_Bay": {
                "exemplarCity": "Cambridge Bay"
              },
              "Campo_Grande": {
                "exemplarCity": "Campo Grande"
              },
              "Cancun": {
                "exemplarCity": "Cancun"
              },
              "Caracas": {
                "exemplarCity": "Caracas"
              },
              "Catamarca": {
                "exemplarCity": "Catamarca"
              },
              "Cayenne": {
                "exemplarCity": "Cayenne"
              },
              "Cayman": {
                "exemplarCity": "Cayman"
              },
              "Chicago": {
                "exemplarCity": "Chicago"
              },
              "Chihuahua": {
                "exemplarCity": "Chihuahua"
              },
              "Coral_Harbour": {
                "exemplarCity": "Atikokan"
              },
              "Cordoba": {
                "exemplarCity": "Cordoba"
              },
              "Costa_Rica": {
                "exemplarCity": "Costa Rica"
              },
              "Creston": {
                "exemplarCity": "Creston"
              },
              "Cuiaba": {
                "exemplarCity": "Cuiaba"
              },
              "Curacao": {
                "exemplarCity": "Curaçao"
              },
              "Danmarkshavn": {
                "exemplarCity": "Danmarkshavn"
              },
              "Dawson": {
                "exemplarCity": "Dawson"
              },
              "Dawson_Creek": {
                "exemplarCity": "Dawson Creek"
              },
              "Denver": {
                "exemplarCity": "Denver"
              },
              "Detroit": {
                "exemplarCity": "Detroit"
              },
              "Dominica": {
                "exemplarCity": "Dominica"
              },
              "Edmonton": {
                "exemplarCity": "Edmonton"
              },
              "Eirunepe": {
                "exemplarCity": "Eirunepe"
              },
              "El_Salvador": {
                "exemplarCity": "El Salvador"
              },
              "Fort_Nelson": {
                "exemplarCity": "Fort Nelson"
              },
              "Fortaleza": {
                "exemplarCity": "Fortaleza"
              },
              "Glace_Bay": {
                "exemplarCity": "Glace Bay"
              },
              "Godthab": {
                "exemplarCity": "Nuuk"
              },
              "Goose_Bay": {
                "exemplarCity": "Goose Bay"
              },
              "Grand_Turk": {
                "exemplarCity": "Grand Turk"
              },
              "Grenada": {
                "exemplarCity": "Grenada"
              },
              "Guadeloupe": {
                "exemplarCity": "Guadeloupe"
              },
              "Guatemala": {
                "exemplarCity": "Guatemala"
              },
              "Guayaquil": {
                "exemplarCity": "Guayaquil"
              },
              "Guyana": {
                "exemplarCity": "Guyana"
              },
              "Halifax": {
                "exemplarCity": "Halifax"
              },
              "Havana": {
                "exemplarCity": "Havana"
              },
              "Hermosillo": {
                "exemplarCity": "Hermosillo"
              },
              "Indiana": {
                "Vincennes": {
                  "exemplarCity": "Vincennes, Indiana"
                },
                "Petersburg": {
                  "exemplarCity": "Petersburg, Indiana"
                },
                "Tell_City": {
                  "exemplarCity": "Tell City, Indiana"
                },
                "Knox": {
                  "exemplarCity": "Knox, Indiana"
                },
                "Winamac": {
                  "exemplarCity": "Winamac, Indiana"
                },
                "Marengo": {
                  "exemplarCity": "Marengo, Indiana"
                },
                "Vevay": {
                  "exemplarCity": "Vevay, Indiana"
                }
              },
              "Indianapolis": {
                "exemplarCity": "Indianapolis"
              },
              "Inuvik": {
                "exemplarCity": "Inuvik"
              },
              "Iqaluit": {
                "exemplarCity": "Iqaluit"
              },
              "Jamaica": {
                "exemplarCity": "Jamaica"
              },
              "Jujuy": {
                "exemplarCity": "Jujuy"
              },
              "Juneau": {
                "exemplarCity": "Juneau"
              },
              "Kentucky": {
                "Monticello": {
                  "exemplarCity": "Monticello, Kentucky"
                }
              },
              "Kralendijk": {
                "exemplarCity": "Kralendijk"
              },
              "La_Paz": {
                "exemplarCity": "La Paz"
              },
              "Lima": {
                "exemplarCity": "Lima"
              },
              "Los_Angeles": {
                "exemplarCity": "Los Angeles"
              },
              "Louisville": {
                "exemplarCity": "Louisville"
              },
              "Lower_Princes": {
                "exemplarCity": "Lower Prince’s Quarter"
              },
              "Maceio": {
                "exemplarCity": "Maceio"
              },
              "Managua": {
                "exemplarCity": "Managua"
              },
              "Manaus": {
                "exemplarCity": "Manaus"
              },
              "Marigot": {
                "exemplarCity": "Marigot"
              },
              "Martinique": {
                "exemplarCity": "Martinique"
              },
              "Matamoros": {
                "exemplarCity": "Matamoros"
              },
              "Mazatlan": {
                "exemplarCity": "Mazatlan"
              },
              "Mendoza": {
                "exemplarCity": "Mendoza"
              },
              "Menominee": {
                "exemplarCity": "Menominee"
              },
              "Merida": {
                "exemplarCity": "Merida"
              },
              "Metlakatla": {
                "exemplarCity": "Metlakatla"
              },
              "Mexico_City": {
                "exemplarCity": "Mexico City"
              },
              "Miquelon": {
                "exemplarCity": "Miquelon"
              },
              "Moncton": {
                "exemplarCity": "Moncton"
              },
              "Monterrey": {
                "exemplarCity": "Monterrey"
              },
              "Montevideo": {
                "exemplarCity": "Montevideo"
              },
              "Montserrat": {
                "exemplarCity": "Montserrat"
              },
              "Nassau": {
                "exemplarCity": "Nassau"
              },
              "New_York": {
                "exemplarCity": "New York"
              },
              "Nipigon": {
                "exemplarCity": "Nipigon"
              },
              "Nome": {
                "exemplarCity": "Nome"
              },
              "Noronha": {
                "exemplarCity": "Noronha"
              },
              "North_Dakota": {
                "Beulah": {
                  "exemplarCity": "Beulah, North Dakota"
                },
                "New_Salem": {
                  "exemplarCity": "New Salem, North Dakota"
                },
                "Center": {
                  "exemplarCity": "Center, North Dakota"
                }
              },
              "Ojinaga": {
                "exemplarCity": "Ojinaga"
              },
              "Panama": {
                "exemplarCity": "Panama"
              },
              "Pangnirtung": {
                "exemplarCity": "Pangnirtung"
              },
              "Paramaribo": {
                "exemplarCity": "Paramaribo"
              },
              "Phoenix": {
                "exemplarCity": "Phoenix"
              },
              "Port-au-Prince": {
                "exemplarCity": "Port-au-Prince"
              },
              "Port_of_Spain": {
                "exemplarCity": "Port of Spain"
              },
              "Porto_Velho": {
                "exemplarCity": "Porto Velho"
              },
              "Puerto_Rico": {
                "exemplarCity": "Puerto Rico"
              },
              "Punta_Arenas": {
                "exemplarCity": "Punta Arenas"
              },
              "Rainy_River": {
                "exemplarCity": "Rainy River"
              },
              "Rankin_Inlet": {
                "exemplarCity": "Rankin Inlet"
              },
              "Recife": {
                "exemplarCity": "Recife"
              },
              "Regina": {
                "exemplarCity": "Regina"
              },
              "Resolute": {
                "exemplarCity": "Resolute"
              },
              "Rio_Branco": {
                "exemplarCity": "Rio Branco"
              },
              "Santarem": {
                "exemplarCity": "Santarem"
              },
              "Santiago": {
                "exemplarCity": "Santiago"
              },
              "Santo_Domingo": {
                "exemplarCity": "Santo Domingo"
              },
              "Sao_Paulo": {
                "exemplarCity": "Sao Paulo"
              },
              "Scoresbysund": {
                "exemplarCity": "Ittoqqortoormiit"
              },
              "Sitka": {
                "exemplarCity": "Sitka"
              },
              "St_Barthelemy": {
                "exemplarCity": "St. Barthélemy"
              },
              "St_Johns": {
                "exemplarCity": "St. John’s"
              },
              "St_Kitts": {
                "exemplarCity": "St. Kitts"
              },
              "St_Lucia": {
                "exemplarCity": "St. Lucia"
              },
              "St_Thomas": {
                "exemplarCity": "St. Thomas"
              },
              "St_Vincent": {
                "exemplarCity": "St. Vincent"
              },
              "Swift_Current": {
                "exemplarCity": "Swift Current"
              },
              "Tegucigalpa": {
                "exemplarCity": "Tegucigalpa"
              },
              "Thule": {
                "exemplarCity": "Thule"
              },
              "Thunder_Bay": {
                "exemplarCity": "Thunder Bay"
              },
              "Tijuana": {
                "exemplarCity": "Tijuana"
              },
              "Toronto": {
                "exemplarCity": "Toronto"
              },
              "Tortola": {
                "exemplarCity": "Tortola"
              },
              "Vancouver": {
                "exemplarCity": "Vancouver"
              },
              "Whitehorse": {
                "exemplarCity": "Whitehorse"
              },
              "Winnipeg": {
                "exemplarCity": "Winnipeg"
              },
              "Yakutat": {
                "exemplarCity": "Yakutat"
              },
              "Yellowknife": {
                "exemplarCity": "Yellowknife"
              }
            },
            "Atlantic": {
              "Azores": {
                "exemplarCity": "Azores"
              },
              "Bermuda": {
                "exemplarCity": "Bermuda"
              },
              "Canary": {
                "exemplarCity": "Canary"
              },
              "Cape_Verde": {
                "exemplarCity": "Cape Verde"
              },
              "Faeroe": {
                "exemplarCity": "Faroe"
              },
              "Madeira": {
                "exemplarCity": "Madeira"
              },
              "Reykjavik": {
                "exemplarCity": "Reykjavik"
              },
              "South_Georgia": {
                "exemplarCity": "South Georgia"
              },
              "St_Helena": {
                "exemplarCity": "St. Helena"
              },
              "Stanley": {
                "exemplarCity": "Stanley"
              }
            },
            "Europe": {
              "Amsterdam": {
                "exemplarCity": "Amsterdam"
              },
              "Andorra": {
                "exemplarCity": "Andorra"
              },
              "Astrakhan": {
                "exemplarCity": "Astrakhan"
              },
              "Athens": {
                "exemplarCity": "Athens"
              },
              "Belgrade": {
                "exemplarCity": "Belgrade"
              },
              "Berlin": {
                "exemplarCity": "Berlin"
              },
              "Bratislava": {
                "exemplarCity": "Bratislava"
              },
              "Brussels": {
                "exemplarCity": "Brussels"
              },
              "Bucharest": {
                "exemplarCity": "Bucharest"
              },
              "Budapest": {
                "exemplarCity": "Budapest"
              },
              "Busingen": {
                "exemplarCity": "Busingen"
              },
              "Chisinau": {
                "exemplarCity": "Chisinau"
              },
              "Copenhagen": {
                "exemplarCity": "Copenhagen"
              },
              "Dublin": {
                "long": {
                  "daylight": "Irish Standard Time"
                },
                "exemplarCity": "Dublin"
              },
              "Gibraltar": {
                "exemplarCity": "Gibraltar"
              },
              "Guernsey": {
                "exemplarCity": "Guernsey"
              },
              "Helsinki": {
                "exemplarCity": "Helsinki"
              },
              "Isle_of_Man": {
                "exemplarCity": "Isle of Man"
              },
              "Istanbul": {
                "exemplarCity": "Istanbul"
              },
              "Jersey": {
                "exemplarCity": "Jersey"
              },
              "Kaliningrad": {
                "exemplarCity": "Kaliningrad"
              },
              "Kiev": {
                "exemplarCity": "Kiev",
                "exemplarCity-alt-formal": "Kyiv"
              },
              "Kirov": {
                "exemplarCity": "Kirov"
              },
              "Lisbon": {
                "exemplarCity": "Lisbon"
              },
              "Ljubljana": {
                "exemplarCity": "Ljubljana"
              },
              "London": {
                "long": {
                  "daylight": "British Summer Time"
                },
                "exemplarCity": "London"
              },
              "Luxembourg": {
                "exemplarCity": "Luxembourg"
              },
              "Madrid": {
                "exemplarCity": "Madrid"
              },
              "Malta": {
                "exemplarCity": "Malta"
              },
              "Mariehamn": {
                "exemplarCity": "Mariehamn"
              },
              "Minsk": {
                "exemplarCity": "Minsk"
              },
              "Monaco": {
                "exemplarCity": "Monaco"
              },
              "Moscow": {
                "exemplarCity": "Moscow"
              },
              "Oslo": {
                "exemplarCity": "Oslo"
              },
              "Paris": {
                "exemplarCity": "Paris"
              },
              "Podgorica": {
                "exemplarCity": "Podgorica"
              },
              "Prague": {
                "exemplarCity": "Prague"
              },
              "Riga": {
                "exemplarCity": "Riga"
              },
              "Rome": {
                "exemplarCity": "Rome"
              },
              "Samara": {
                "exemplarCity": "Samara"
              },
              "San_Marino": {
                "exemplarCity": "San Marino"
              },
              "Sarajevo": {
                "exemplarCity": "Sarajevo"
              },
              "Saratov": {
                "exemplarCity": "Saratov"
              },
              "Simferopol": {
                "exemplarCity": "Simferopol"
              },
              "Skopje": {
                "exemplarCity": "Skopje"
              },
              "Sofia": {
                "exemplarCity": "Sofia"
              },
              "Stockholm": {
                "exemplarCity": "Stockholm"
              },
              "Tallinn": {
                "exemplarCity": "Tallinn"
              },
              "Tirane": {
                "exemplarCity": "Tirane"
              },
              "Ulyanovsk": {
                "exemplarCity": "Ulyanovsk"
              },
              "Uzhgorod": {
                "exemplarCity": "Uzhhorod"
              },
              "Vaduz": {
                "exemplarCity": "Vaduz"
              },
              "Vatican": {
                "exemplarCity": "Vatican"
              },
              "Vienna": {
                "exemplarCity": "Vienna"
              },
              "Vilnius": {
                "exemplarCity": "Vilnius"
              },
              "Volgograd": {
                "exemplarCity": "Volgograd"
              },
              "Warsaw": {
                "exemplarCity": "Warsaw"
              },
              "Zagreb": {
                "exemplarCity": "Zagreb"
              },
              "Zaporozhye": {
                "exemplarCity": "Zaporozhye"
              },
              "Zurich": {
                "exemplarCity": "Zurich"
              }
            },
            "Africa": {
              "Abidjan": {
                "exemplarCity": "Abidjan"
              },
              "Accra": {
                "exemplarCity": "Accra"
              },
              "Addis_Ababa": {
                "exemplarCity": "Addis Ababa"
              },
              "Algiers": {
                "exemplarCity": "Algiers"
              },
              "Asmera": {
                "exemplarCity": "Asmara"
              },
              "Bamako": {
                "exemplarCity": "Bamako"
              },
              "Bangui": {
                "exemplarCity": "Bangui"
              },
              "Banjul": {
                "exemplarCity": "Banjul"
              },
              "Bissau": {
                "exemplarCity": "Bissau"
              },
              "Blantyre": {
                "exemplarCity": "Blantyre"
              },
              "Brazzaville": {
                "exemplarCity": "Brazzaville"
              },
              "Bujumbura": {
                "exemplarCity": "Bujumbura"
              },
              "Cairo": {
                "exemplarCity": "Cairo"
              },
              "Casablanca": {
                "exemplarCity": "Casablanca"
              },
              "Ceuta": {
                "exemplarCity": "Ceuta"
              },
              "Conakry": {
                "exemplarCity": "Conakry"
              },
              "Dakar": {
                "exemplarCity": "Dakar"
              },
              "Dar_es_Salaam": {
                "exemplarCity": "Dar es Salaam"
              },
              "Djibouti": {
                "exemplarCity": "Djibouti"
              },
              "Douala": {
                "exemplarCity": "Douala"
              },
              "El_Aaiun": {
                "exemplarCity": "El Aaiun"
              },
              "Freetown": {
                "exemplarCity": "Freetown"
              },
              "Gaborone": {
                "exemplarCity": "Gaborone"
              },
              "Harare": {
                "exemplarCity": "Harare"
              },
              "Johannesburg": {
                "exemplarCity": "Johannesburg"
              },
              "Juba": {
                "exemplarCity": "Juba"
              },
              "Kampala": {
                "exemplarCity": "Kampala"
              },
              "Khartoum": {
                "exemplarCity": "Khartoum"
              },
              "Kigali": {
                "exemplarCity": "Kigali"
              },
              "Kinshasa": {
                "exemplarCity": "Kinshasa"
              },
              "Lagos": {
                "exemplarCity": "Lagos"
              },
              "Libreville": {
                "exemplarCity": "Libreville"
              },
              "Lome": {
                "exemplarCity": "Lome"
              },
              "Luanda": {
                "exemplarCity": "Luanda"
              },
              "Lubumbashi": {
                "exemplarCity": "Lubumbashi"
              },
              "Lusaka": {
                "exemplarCity": "Lusaka"
              },
              "Malabo": {
                "exemplarCity": "Malabo"
              },
              "Maputo": {
                "exemplarCity": "Maputo"
              },
              "Maseru": {
                "exemplarCity": "Maseru"
              },
              "Mbabane": {
                "exemplarCity": "Mbabane"
              },
              "Mogadishu": {
                "exemplarCity": "Mogadishu"
              },
              "Monrovia": {
                "exemplarCity": "Monrovia"
              },
              "Nairobi": {
                "exemplarCity": "Nairobi"
              },
              "Ndjamena": {
                "exemplarCity": "Ndjamena"
              },
              "Niamey": {
                "exemplarCity": "Niamey"
              },
              "Nouakchott": {
                "exemplarCity": "Nouakchott"
              },
              "Ouagadougou": {
                "exemplarCity": "Ouagadougou"
              },
              "Porto-Novo": {
                "exemplarCity": "Porto-Novo"
              },
              "Sao_Tome": {
                "exemplarCity": "São Tomé"
              },
              "Tripoli": {
                "exemplarCity": "Tripoli"
              },
              "Tunis": {
                "exemplarCity": "Tunis"
              },
              "Windhoek": {
                "exemplarCity": "Windhoek"
              }
            },
            "Asia": {
              "Aden": {
                "exemplarCity": "Aden"
              },
              "Almaty": {
                "exemplarCity": "Almaty"
              },
              "Amman": {
                "exemplarCity": "Amman"
              },
              "Anadyr": {
                "exemplarCity": "Anadyr"
              },
              "Aqtau": {
                "exemplarCity": "Aqtau"
              },
              "Aqtobe": {
                "exemplarCity": "Aqtobe"
              },
              "Ashgabat": {
                "exemplarCity": "Ashgabat"
              },
              "Atyrau": {
                "exemplarCity": "Atyrau"
              },
              "Baghdad": {
                "exemplarCity": "Baghdad"
              },
              "Bahrain": {
                "exemplarCity": "Bahrain"
              },
              "Baku": {
                "exemplarCity": "Baku"
              },
              "Bangkok": {
                "exemplarCity": "Bangkok"
              },
              "Barnaul": {
                "exemplarCity": "Barnaul"
              },
              "Beirut": {
                "exemplarCity": "Beirut"
              },
              "Bishkek": {
                "exemplarCity": "Bishkek"
              },
              "Brunei": {
                "exemplarCity": "Brunei"
              },
              "Calcutta": {
                "exemplarCity": "Kolkata"
              },
              "Chita": {
                "exemplarCity": "Chita"
              },
              "Choibalsan": {
                "exemplarCity": "Choibalsan"
              },
              "Colombo": {
                "exemplarCity": "Colombo"
              },
              "Damascus": {
                "exemplarCity": "Damascus"
              },
              "Dhaka": {
                "exemplarCity": "Dhaka"
              },
              "Dili": {
                "exemplarCity": "Dili"
              },
              "Dubai": {
                "exemplarCity": "Dubai"
              },
              "Dushanbe": {
                "exemplarCity": "Dushanbe"
              },
              "Famagusta": {
                "exemplarCity": "Famagusta"
              },
              "Gaza": {
                "exemplarCity": "Gaza"
              },
              "Hebron": {
                "exemplarCity": "Hebron"
              },
              "Hong_Kong": {
                "exemplarCity": "Hong Kong"
              },
              "Hovd": {
                "exemplarCity": "Hovd"
              },
              "Irkutsk": {
                "exemplarCity": "Irkutsk"
              },
              "Jakarta": {
                "exemplarCity": "Jakarta"
              },
              "Jayapura": {
                "exemplarCity": "Jayapura"
              },
              "Jerusalem": {
                "exemplarCity": "Jerusalem"
              },
              "Kabul": {
                "exemplarCity": "Kabul"
              },
              "Kamchatka": {
                "exemplarCity": "Kamchatka"
              },
              "Karachi": {
                "exemplarCity": "Karachi"
              },
              "Katmandu": {
                "exemplarCity": "Kathmandu"
              },
              "Khandyga": {
                "exemplarCity": "Khandyga"
              },
              "Krasnoyarsk": {
                "exemplarCity": "Krasnoyarsk"
              },
              "Kuala_Lumpur": {
                "exemplarCity": "Kuala Lumpur"
              },
              "Kuching": {
                "exemplarCity": "Kuching"
              },
              "Kuwait": {
                "exemplarCity": "Kuwait"
              },
              "Macau": {
                "exemplarCity": "Macao"
              },
              "Magadan": {
                "exemplarCity": "Magadan"
              },
              "Makassar": {
                "exemplarCity": "Makassar"
              },
              "Manila": {
                "exemplarCity": "Manila"
              },
              "Muscat": {
                "exemplarCity": "Muscat"
              },
              "Nicosia": {
                "exemplarCity": "Nicosia"
              },
              "Novokuznetsk": {
                "exemplarCity": "Novokuznetsk"
              },
              "Novosibirsk": {
                "exemplarCity": "Novosibirsk"
              },
              "Omsk": {
                "exemplarCity": "Omsk"
              },
              "Oral": {
                "exemplarCity": "Oral"
              },
              "Phnom_Penh": {
                "exemplarCity": "Phnom Penh"
              },
              "Pontianak": {
                "exemplarCity": "Pontianak"
              },
              "Pyongyang": {
                "exemplarCity": "Pyongyang"
              },
              "Qatar": {
                "exemplarCity": "Qatar"
              },
              "Qostanay": {
                "exemplarCity": "Kostanay"
              },
              "Qyzylorda": {
                "exemplarCity": "Qyzylorda"
              },
              "Rangoon": {
                "exemplarCity": "Yangon"
              },
              "Riyadh": {
                "exemplarCity": "Riyadh"
              },
              "Saigon": {
                "exemplarCity": "Ho Chi Minh City"
              },
              "Sakhalin": {
                "exemplarCity": "Sakhalin"
              },
              "Samarkand": {
                "exemplarCity": "Samarkand"
              },
              "Seoul": {
                "exemplarCity": "Seoul"
              },
              "Shanghai": {
                "exemplarCity": "Shanghai"
              },
              "Singapore": {
                "exemplarCity": "Singapore"
              },
              "Srednekolymsk": {
                "exemplarCity": "Srednekolymsk"
              },
              "Taipei": {
                "exemplarCity": "Taipei"
              },
              "Tashkent": {
                "exemplarCity": "Tashkent"
              },
              "Tbilisi": {
                "exemplarCity": "Tbilisi"
              },
              "Tehran": {
                "exemplarCity": "Tehran"
              },
              "Thimphu": {
                "exemplarCity": "Thimphu"
              },
              "Tokyo": {
                "exemplarCity": "Tokyo"
              },
              "Tomsk": {
                "exemplarCity": "Tomsk"
              },
              "Ulaanbaatar": {
                "exemplarCity": "Ulaanbaatar"
              },
              "Urumqi": {
                "exemplarCity": "Urumqi"
              },
              "Ust-Nera": {
                "exemplarCity": "Ust-Nera"
              },
              "Vientiane": {
                "exemplarCity": "Vientiane"
              },
              "Vladivostok": {
                "exemplarCity": "Vladivostok"
              },
              "Yakutsk": {
                "exemplarCity": "Yakutsk"
              },
              "Yekaterinburg": {
                "exemplarCity": "Yekaterinburg"
              },
              "Yerevan": {
                "exemplarCity": "Yerevan"
              }
            },
            "Indian": {
              "Antananarivo": {
                "exemplarCity": "Antananarivo"
              },
              "Chagos": {
                "exemplarCity": "Chagos"
              },
              "Christmas": {
                "exemplarCity": "Christmas"
              },
              "Cocos": {
                "exemplarCity": "Cocos"
              },
              "Comoro": {
                "exemplarCity": "Comoro"
              },
              "Kerguelen": {
                "exemplarCity": "Kerguelen"
              },
              "Mahe": {
                "exemplarCity": "Mahe"
              },
              "Maldives": {
                "exemplarCity": "Maldives"
              },
              "Mauritius": {
                "exemplarCity": "Mauritius"
              },
              "Mayotte": {
                "exemplarCity": "Mayotte"
              },
              "Reunion": {
                "exemplarCity": "Réunion"
              }
            },
            "Australia": {
              "Adelaide": {
                "exemplarCity": "Adelaide"
              },
              "Brisbane": {
                "exemplarCity": "Brisbane"
              },
              "Broken_Hill": {
                "exemplarCity": "Broken Hill"
              },
              "Currie": {
                "exemplarCity": "Currie"
              },
              "Darwin": {
                "exemplarCity": "Darwin"
              },
              "Eucla": {
                "exemplarCity": "Eucla"
              },
              "Hobart": {
                "exemplarCity": "Hobart"
              },
              "Lindeman": {
                "exemplarCity": "Lindeman"
              },
              "Lord_Howe": {
                "exemplarCity": "Lord Howe"
              },
              "Melbourne": {
                "exemplarCity": "Melbourne"
              },
              "Perth": {
                "exemplarCity": "Perth"
              },
              "Sydney": {
                "exemplarCity": "Sydney"
              }
            },
            "Pacific": {
              "Apia": {
                "exemplarCity": "Apia"
              },
              "Auckland": {
                "exemplarCity": "Auckland"
              },
              "Bougainville": {
                "exemplarCity": "Bougainville"
              },
              "Chatham": {
                "exemplarCity": "Chatham"
              },
              "Easter": {
                "exemplarCity": "Easter"
              },
              "Efate": {
                "exemplarCity": "Efate"
              },
              "Enderbury": {
                "exemplarCity": "Enderbury"
              },
              "Fakaofo": {
                "exemplarCity": "Fakaofo"
              },
              "Fiji": {
                "exemplarCity": "Fiji"
              },
              "Funafuti": {
                "exemplarCity": "Funafuti"
              },
              "Galapagos": {
                "exemplarCity": "Galapagos"
              },
              "Gambier": {
                "exemplarCity": "Gambier"
              },
              "Guadalcanal": {
                "exemplarCity": "Guadalcanal"
              },
              "Guam": {
                "exemplarCity": "Guam"
              },
              "Honolulu": {
                "short": {
                  "generic": "HST",
                  "standard": "HST",
                  "daylight": "HDT"
                }
              },
              "Johnston": {
                "exemplarCity": "Johnston"
              },
              "Kiritimati": {
                "exemplarCity": "Kiritimati"
              },
              "Kosrae": {
                "exemplarCity": "Kosrae"
              },
              "Kwajalein": {
                "exemplarCity": "Kwajalein"
              },
              "Majuro": {
                "exemplarCity": "Majuro"
              },
              "Marquesas": {
                "exemplarCity": "Marquesas"
              },
              "Midway": {
                "exemplarCity": "Midway"
              },
              "Nauru": {
                "exemplarCity": "Nauru"
              },
              "Niue": {
                "exemplarCity": "Niue"
              },
              "Norfolk": {
                "exemplarCity": "Norfolk"
              },
              "Noumea": {
                "exemplarCity": "Noumea"
              },
              "Pago_Pago": {
                "exemplarCity": "Pago Pago"
              },
              "Palau": {
                "exemplarCity": "Palau"
              },
              "Pitcairn": {
                "exemplarCity": "Pitcairn"
              },
              "Ponape": {
                "exemplarCity": "Pohnpei"
              },
              "Port_Moresby": {
                "exemplarCity": "Port Moresby"
              },
              "Rarotonga": {
                "exemplarCity": "Rarotonga"
              },
              "Saipan": {
                "exemplarCity": "Saipan"
              },
              "Tahiti": {
                "exemplarCity": "Tahiti"
              },
              "Tarawa": {
                "exemplarCity": "Tarawa"
              },
              "Tongatapu": {
                "exemplarCity": "Tongatapu"
              },
              "Truk": {
                "exemplarCity": "Chuuk"
              },
              "Wake": {
                "exemplarCity": "Wake"
              },
              "Wallis": {
                "exemplarCity": "Wallis"
              }
            },
            "Arctic": {
              "Longyearbyen": {
                "exemplarCity": "Longyearbyen"
              }
            },
            "Antarctica": {
              "Casey": {
                "exemplarCity": "Casey"
              },
              "Davis": {
                "exemplarCity": "Davis"
              },
              "DumontDUrville": {
                "exemplarCity": "Dumont d’Urville"
              },
              "Macquarie": {
                "exemplarCity": "Macquarie"
              },
              "Mawson": {
                "exemplarCity": "Mawson"
              },
              "McMurdo": {
                "exemplarCity": "McMurdo"
              },
              "Palmer": {
                "exemplarCity": "Palmer"
              },
              "Rothera": {
                "exemplarCity": "Rothera"
              },
              "Syowa": {
                "exemplarCity": "Syowa"
              },
              "Troll": {
                "exemplarCity": "Troll"
              },
              "Vostok": {
                "exemplarCity": "Vostok"
              }
            },
            "Etc": {
              "UTC": {
                "long": {
                  "standard": "Coordinated Universal Time"
                },
                "short": {
                  "standard": "UTC"
                }
              },
              "Unknown": {
                "exemplarCity": "Unknown City"
              }
            }
          },
          "metazone": {
            "Acre": {
              "long": {
                "generic": "Acre Time",
                "standard": "Acre Standard Time",
                "daylight": "Acre Summer Time"
              }
            },
            "Afghanistan": {
              "long": {
                "standard": "Afghanistan Time"
              }
            },
            "Africa_Central": {
              "long": {
                "standard": "Central Africa Time"
              }
            },
            "Africa_Eastern": {
              "long": {
                "standard": "East Africa Time"
              }
            },
            "Africa_Southern": {
              "long": {
                "standard": "South Africa Standard Time"
              }
            },
            "Africa_Western": {
              "long": {
                "generic": "West Africa Time",
                "standard": "West Africa Standard Time",
                "daylight": "West Africa Summer Time"
              }
            },
            "Alaska": {
              "long": {
                "generic": "Alaska Time",
                "standard": "Alaska Standard Time",
                "daylight": "Alaska Daylight Time"
              },
              "short": {
                "generic": "AKT",
                "standard": "AKST",
                "daylight": "AKDT"
              }
            },
            "Almaty": {
              "long": {
                "generic": "Almaty Time",
                "standard": "Almaty Standard Time",
                "daylight": "Almaty Summer Time"
              }
            },
            "Amazon": {
              "long": {
                "generic": "Amazon Time",
                "standard": "Amazon Standard Time",
                "daylight": "Amazon Summer Time"
              }
            },
            "America_Central": {
              "long": {
                "generic": "Central Time",
                "standard": "Central Standard Time",
                "daylight": "Central Daylight Time"
              },
              "short": {
                "generic": "CT",
                "standard": "CST",
                "daylight": "CDT"
              }
            },
            "America_Eastern": {
              "long": {
                "generic": "Eastern Time",
                "standard": "Eastern Standard Time",
                "daylight": "Eastern Daylight Time"
              },
              "short": {
                "generic": "ET",
                "standard": "EST",
                "daylight": "EDT"
              }
            },
            "America_Mountain": {
              "long": {
                "generic": "Mountain Time",
                "standard": "Mountain Standard Time",
                "daylight": "Mountain Daylight Time"
              },
              "short": {
                "generic": "MT",
                "standard": "MST",
                "daylight": "MDT"
              }
            },
            "America_Pacific": {
              "long": {
                "generic": "Pacific Time",
                "standard": "Pacific Standard Time",
                "daylight": "Pacific Daylight Time"
              },
              "short": {
                "generic": "PT",
                "standard": "PST",
                "daylight": "PDT"
              }
            },
            "Anadyr": {
              "long": {
                "generic": "Anadyr Time",
                "standard": "Anadyr Standard Time",
                "daylight": "Anadyr Summer Time"
              }
            },
            "Apia": {
              "long": {
                "generic": "Apia Time",
                "standard": "Apia Standard Time",
                "daylight": "Apia Daylight Time"
              }
            },
            "Aqtau": {
              "long": {
                "generic": "Aqtau Time",
                "standard": "Aqtau Standard Time",
                "daylight": "Aqtau Summer Time"
              }
            },
            "Aqtobe": {
              "long": {
                "generic": "Aqtobe Time",
                "standard": "Aqtobe Standard Time",
                "daylight": "Aqtobe Summer Time"
              }
            },
            "Arabian": {
              "long": {
                "generic": "Arabian Time",
                "standard": "Arabian Standard Time",
                "daylight": "Arabian Daylight Time"
              }
            },
            "Argentina": {
              "long": {
                "generic": "Argentina Time",
                "standard": "Argentina Standard Time",
                "daylight": "Argentina Summer Time"
              }
            },
            "Argentina_Western": {
              "long": {
                "generic": "Western Argentina Time",
                "standard": "Western Argentina Standard Time",
                "daylight": "Western Argentina Summer Time"
              }
            },
            "Armenia": {
              "long": {
                "generic": "Armenia Time",
                "standard": "Armenia Standard Time",
                "daylight": "Armenia Summer Time"
              }
            },
            "Atlantic": {
              "long": {
                "generic": "Atlantic Time",
                "standard": "Atlantic Standard Time",
                "daylight": "Atlantic Daylight Time"
              },
              "short": {
                "generic": "AT",
                "standard": "AST",
                "daylight": "ADT"
              }
            },
            "Australia_Central": {
              "long": {
                "generic": "Central Australia Time",
                "standard": "Australian Central Standard Time",
                "daylight": "Australian Central Daylight Time"
              }
            },
            "Australia_CentralWestern": {
              "long": {
                "generic": "Australian Central Western Time",
                "standard": "Australian Central Western Standard Time",
                "daylight": "Australian Central Western Daylight Time"
              }
            },
            "Australia_Eastern": {
              "long": {
                "generic": "Eastern Australia Time",
                "standard": "Australian Eastern Standard Time",
                "daylight": "Australian Eastern Daylight Time"
              }
            },
            "Australia_Western": {
              "long": {
                "generic": "Western Australia Time",
                "standard": "Australian Western Standard Time",
                "daylight": "Australian Western Daylight Time"
              }
            },
            "Azerbaijan": {
              "long": {
                "generic": "Azerbaijan Time",
                "standard": "Azerbaijan Standard Time",
                "daylight": "Azerbaijan Summer Time"
              }
            },
            "Azores": {
              "long": {
                "generic": "Azores Time",
                "standard": "Azores Standard Time",
                "daylight": "Azores Summer Time"
              }
            },
            "Bangladesh": {
              "long": {
                "generic": "Bangladesh Time",
                "standard": "Bangladesh Standard Time",
                "daylight": "Bangladesh Summer Time"
              }
            },
            "Bhutan": {
              "long": {
                "standard": "Bhutan Time"
              }
            },
            "Bolivia": {
              "long": {
                "standard": "Bolivia Time"
              }
            },
            "Brasilia": {
              "long": {
                "generic": "Brasilia Time",
                "standard": "Brasilia Standard Time",
                "daylight": "Brasilia Summer Time"
              }
            },
            "Brunei": {
              "long": {
                "standard": "Brunei Darussalam Time"
              }
            },
            "Cape_Verde": {
              "long": {
                "generic": "Cape Verde Time",
                "standard": "Cape Verde Standard Time",
                "daylight": "Cape Verde Summer Time"
              }
            },
            "Casey": {
              "long": {
                "standard": "Casey Time"
              }
            },
            "Chamorro": {
              "long": {
                "standard": "Chamorro Standard Time"
              }
            },
            "Chatham": {
              "long": {
                "generic": "Chatham Time",
                "standard": "Chatham Standard Time",
                "daylight": "Chatham Daylight Time"
              }
            },
            "Chile": {
              "long": {
                "generic": "Chile Time",
                "standard": "Chile Standard Time",
                "daylight": "Chile Summer Time"
              }
            },
            "China": {
              "long": {
                "generic": "China Time",
                "standard": "China Standard Time",
                "daylight": "China Daylight Time"
              }
            },
            "Choibalsan": {
              "long": {
                "generic": "Choibalsan Time",
                "standard": "Choibalsan Standard Time",
                "daylight": "Choibalsan Summer Time"
              }
            },
            "Christmas": {
              "long": {
                "standard": "Christmas Island Time"
              }
            },
            "Cocos": {
              "long": {
                "standard": "Cocos Islands Time"
              }
            },
            "Colombia": {
              "long": {
                "generic": "Colombia Time",
                "standard": "Colombia Standard Time",
                "daylight": "Colombia Summer Time"
              }
            },
            "Cook": {
              "long": {
                "generic": "Cook Islands Time",
                "standard": "Cook Islands Standard Time",
                "daylight": "Cook Islands Half Summer Time"
              }
            },
            "Cuba": {
              "long": {
                "generic": "Cuba Time",
                "standard": "Cuba Standard Time",
                "daylight": "Cuba Daylight Time"
              }
            },
            "Davis": {
              "long": {
                "standard": "Davis Time"
              }
            },
            "DumontDUrville": {
              "long": {
                "standard": "Dumont-d’Urville Time"
              }
            },
            "East_Timor": {
              "long": {
                "standard": "East Timor Time"
              }
            },
            "Easter": {
              "long": {
                "generic": "Easter Island Time",
                "standard": "Easter Island Standard Time",
                "daylight": "Easter Island Summer Time"
              }
            },
            "Ecuador": {
              "long": {
                "standard": "Ecuador Time"
              }
            },
            "Europe_Central": {
              "long": {
                "generic": "Central European Time",
                "standard": "Central European Standard Time",
                "daylight": "Central European Summer Time"
              }
            },
            "Europe_Eastern": {
              "long": {
                "generic": "Eastern European Time",
                "standard": "Eastern European Standard Time",
                "daylight": "Eastern European Summer Time"
              }
            },
            "Europe_Further_Eastern": {
              "long": {
                "standard": "Further-eastern European Time"
              }
            },
            "Europe_Western": {
              "long": {
                "generic": "Western European Time",
                "standard": "Western European Standard Time",
                "daylight": "Western European Summer Time"
              }
            },
            "Falkland": {
              "long": {
                "generic": "Falkland Islands Time",
                "standard": "Falkland Islands Standard Time",
                "daylight": "Falkland Islands Summer Time"
              }
            },
            "Fiji": {
              "long": {
                "generic": "Fiji Time",
                "standard": "Fiji Standard Time",
                "daylight": "Fiji Summer Time"
              }
            },
            "French_Guiana": {
              "long": {
                "standard": "French Guiana Time"
              }
            },
            "French_Southern": {
              "long": {
                "standard": "French Southern & Antarctic Time"
              }
            },
            "Galapagos": {
              "long": {
                "standard": "Galapagos Time"
              }
            },
            "Gambier": {
              "long": {
                "standard": "Gambier Time"
              }
            },
            "Georgia": {
              "long": {
                "generic": "Georgia Time",
                "standard": "Georgia Standard Time",
                "daylight": "Georgia Summer Time"
              }
            },
            "Gilbert_Islands": {
              "long": {
                "standard": "Gilbert Islands Time"
              }
            },
            "GMT": {
              "long": {
                "standard": "Greenwich Mean Time"
              },
              "short": {
                "standard": "GMT"
              }
            },
            "Greenland_Eastern": {
              "long": {
                "generic": "East Greenland Time",
                "standard": "East Greenland Standard Time",
                "daylight": "East Greenland Summer Time"
              }
            },
            "Greenland_Western": {
              "long": {
                "generic": "West Greenland Time",
                "standard": "West Greenland Standard Time",
                "daylight": "West Greenland Summer Time"
              }
            },
            "Guam": {
              "long": {
                "standard": "Guam Standard Time"
              }
            },
            "Gulf": {
              "long": {
                "standard": "Gulf Standard Time"
              }
            },
            "Guyana": {
              "long": {
                "standard": "Guyana Time"
              }
            },
            "Hawaii_Aleutian": {
              "long": {
                "generic": "Hawaii-Aleutian Time",
                "standard": "Hawaii-Aleutian Standard Time",
                "daylight": "Hawaii-Aleutian Daylight Time"
              },
              "short": {
                "generic": "HAT",
                "standard": "HAST",
                "daylight": "HADT"
              }
            },
            "Hong_Kong": {
              "long": {
                "generic": "Hong Kong Time",
                "standard": "Hong Kong Standard Time",
                "daylight": "Hong Kong Summer Time"
              }
            },
            "Hovd": {
              "long": {
                "generic": "Hovd Time",
                "standard": "Hovd Standard Time",
                "daylight": "Hovd Summer Time"
              }
            },
            "India": {
              "long": {
                "standard": "India Standard Time"
              }
            },
            "Indian_Ocean": {
              "long": {
                "standard": "Indian Ocean Time"
              }
            },
            "Indochina": {
              "long": {
                "standard": "Indochina Time"
              }
            },
            "Indonesia_Central": {
              "long": {
                "standard": "Central Indonesia Time"
              }
            },
            "Indonesia_Eastern": {
              "long": {
                "standard": "Eastern Indonesia Time"
              }
            },
            "Indonesia_Western": {
              "long": {
                "standard": "Western Indonesia Time"
              }
            },
            "Iran": {
              "long": {
                "generic": "Iran Time",
                "standard": "Iran Standard Time",
                "daylight": "Iran Daylight Time"
              }
            },
            "Irkutsk": {
              "long": {
                "generic": "Irkutsk Time",
                "standard": "Irkutsk Standard Time",
                "daylight": "Irkutsk Summer Time"
              }
            },
            "Israel": {
              "long": {
                "generic": "Israel Time",
                "standard": "Israel Standard Time",
                "daylight": "Israel Daylight Time"
              }
            },
            "Japan": {
              "long": {
                "generic": "Japan Time",
                "standard": "Japan Standard Time",
                "daylight": "Japan Daylight Time"
              }
            },
            "Kamchatka": {
              "long": {
                "generic": "Petropavlovsk-Kamchatski Time",
                "standard": "Petropavlovsk-Kamchatski Standard Time",
                "daylight": "Petropavlovsk-Kamchatski Summer Time"
              }
            },
            "Kazakhstan_Eastern": {
              "long": {
                "standard": "East Kazakhstan Time"
              }
            },
            "Kazakhstan_Western": {
              "long": {
                "standard": "West Kazakhstan Time"
              }
            },
            "Korea": {
              "long": {
                "generic": "Korean Time",
                "standard": "Korean Standard Time",
                "daylight": "Korean Daylight Time"
              }
            },
            "Kosrae": {
              "long": {
                "standard": "Kosrae Time"
              }
            },
            "Krasnoyarsk": {
              "long": {
                "generic": "Krasnoyarsk Time",
                "standard": "Krasnoyarsk Standard Time",
                "daylight": "Krasnoyarsk Summer Time"
              }
            },
            "Kyrgystan": {
              "long": {
                "standard": "Kyrgyzstan Time"
              }
            },
            "Lanka": {
              "long": {
                "standard": "Lanka Time"
              }
            },
            "Line_Islands": {
              "long": {
                "standard": "Line Islands Time"
              }
            },
            "Lord_Howe": {
              "long": {
                "generic": "Lord Howe Time",
                "standard": "Lord Howe Standard Time",
                "daylight": "Lord Howe Daylight Time"
              }
            },
            "Macau": {
              "long": {
                "generic": "Macao Time",
                "standard": "Macao Standard Time",
                "daylight": "Macao Summer Time"
              }
            },
            "Macquarie": {
              "long": {
                "standard": "Macquarie Island Time"
              }
            },
            "Magadan": {
              "long": {
                "generic": "Magadan Time",
                "standard": "Magadan Standard Time",
                "daylight": "Magadan Summer Time"
              }
            },
            "Malaysia": {
              "long": {
                "standard": "Malaysia Time"
              }
            },
            "Maldives": {
              "long": {
                "standard": "Maldives Time"
              }
            },
            "Marquesas": {
              "long": {
                "standard": "Marquesas Time"
              }
            },
            "Marshall_Islands": {
              "long": {
                "standard": "Marshall Islands Time"
              }
            },
            "Mauritius": {
              "long": {
                "generic": "Mauritius Time",
                "standard": "Mauritius Standard Time",
                "daylight": "Mauritius Summer Time"
              }
            },
            "Mawson": {
              "long": {
                "standard": "Mawson Time"
              }
            },
            "Mexico_Northwest": {
              "long": {
                "generic": "Northwest Mexico Time",
                "standard": "Northwest Mexico Standard Time",
                "daylight": "Northwest Mexico Daylight Time"
              }
            },
            "Mexico_Pacific": {
              "long": {
                "generic": "Mexican Pacific Time",
                "standard": "Mexican Pacific Standard Time",
                "daylight": "Mexican Pacific Daylight Time"
              }
            },
            "Mongolia": {
              "long": {
                "generic": "Ulaanbaatar Time",
                "standard": "Ulaanbaatar Standard Time",
                "daylight": "Ulaanbaatar Summer Time"
              }
            },
            "Moscow": {
              "long": {
                "generic": "Moscow Time",
                "standard": "Moscow Standard Time",
                "daylight": "Moscow Summer Time"
              }
            },
            "Myanmar": {
              "long": {
                "standard": "Myanmar Time"
              }
            },
            "Nauru": {
              "long": {
                "standard": "Nauru Time"
              }
            },
            "Nepal": {
              "long": {
                "standard": "Nepal Time"
              }
            },
            "New_Caledonia": {
              "long": {
                "generic": "New Caledonia Time",
                "standard": "New Caledonia Standard Time",
                "daylight": "New Caledonia Summer Time"
              }
            },
            "New_Zealand": {
              "long": {
                "generic": "New Zealand Time",
                "standard": "New Zealand Standard Time",
                "daylight": "New Zealand Daylight Time"
              }
            },
            "Newfoundland": {
              "long": {
                "generic": "Newfoundland Time",
                "standard": "Newfoundland Standard Time",
                "daylight": "Newfoundland Daylight Time"
              }
            },
            "Niue": {
              "long": {
                "standard": "Niue Time"
              }
            },
            "Norfolk": {
              "long": {
                "standard": "Norfolk Island Time"
              }
            },
            "Noronha": {
              "long": {
                "generic": "Fernando de Noronha Time",
                "standard": "Fernando de Noronha Standard Time",
                "daylight": "Fernando de Noronha Summer Time"
              }
            },
            "North_Mariana": {
              "long": {
                "standard": "North Mariana Islands Time"
              }
            },
            "Novosibirsk": {
              "long": {
                "generic": "Novosibirsk Time",
                "standard": "Novosibirsk Standard Time",
                "daylight": "Novosibirsk Summer Time"
              }
            },
            "Omsk": {
              "long": {
                "generic": "Omsk Time",
                "standard": "Omsk Standard Time",
                "daylight": "Omsk Summer Time"
              }
            },
            "Pakistan": {
              "long": {
                "generic": "Pakistan Time",
                "standard": "Pakistan Standard Time",
                "daylight": "Pakistan Summer Time"
              }
            },
            "Palau": {
              "long": {
                "standard": "Palau Time"
              }
            },
            "Papua_New_Guinea": {
              "long": {
                "standard": "Papua New Guinea Time"
              }
            },
            "Paraguay": {
              "long": {
                "generic": "Paraguay Time",
                "standard": "Paraguay Standard Time",
                "daylight": "Paraguay Summer Time"
              }
            },
            "Peru": {
              "long": {
                "generic": "Peru Time",
                "standard": "Peru Standard Time",
                "daylight": "Peru Summer Time"
              }
            },
            "Philippines": {
              "long": {
                "generic": "Philippine Time",
                "standard": "Philippine Standard Time",
                "daylight": "Philippine Summer Time"
              }
            },
            "Phoenix_Islands": {
              "long": {
                "standard": "Phoenix Islands Time"
              }
            },
            "Pierre_Miquelon": {
              "long": {
                "generic": "St. Pierre & Miquelon Time",
                "standard": "St. Pierre & Miquelon Standard Time",
                "daylight": "St. Pierre & Miquelon Daylight Time"
              }
            },
            "Pitcairn": {
              "long": {
                "standard": "Pitcairn Time"
              }
            },
            "Ponape": {
              "long": {
                "standard": "Ponape Time"
              }
            },
            "Pyongyang": {
              "long": {
                "standard": "Pyongyang Time"
              }
            },
            "Qyzylorda": {
              "long": {
                "generic": "Qyzylorda Time",
                "standard": "Qyzylorda Standard Time",
                "daylight": "Qyzylorda Summer Time"
              }
            },
            "Reunion": {
              "long": {
                "standard": "Réunion Time"
              }
            },
            "Rothera": {
              "long": {
                "standard": "Rothera Time"
              }
            },
            "Sakhalin": {
              "long": {
                "generic": "Sakhalin Time",
                "standard": "Sakhalin Standard Time",
                "daylight": "Sakhalin Summer Time"
              }
            },
            "Samara": {
              "long": {
                "generic": "Samara Time",
                "standard": "Samara Standard Time",
                "daylight": "Samara Summer Time"
              }
            },
            "Samoa": {
              "long": {
                "generic": "Samoa Time",
                "standard": "Samoa Standard Time",
                "daylight": "Samoa Daylight Time"
              }
            },
            "Seychelles": {
              "long": {
                "standard": "Seychelles Time"
              }
            },
            "Singapore": {
              "long": {
                "standard": "Singapore Standard Time"
              }
            },
            "Solomon": {
              "long": {
                "standard": "Solomon Islands Time"
              }
            },
            "South_Georgia": {
              "long": {
                "standard": "South Georgia Time"
              }
            },
            "Suriname": {
              "long": {
                "standard": "Suriname Time"
              }
            },
            "Syowa": {
              "long": {
                "standard": "Syowa Time"
              }
            },
            "Tahiti": {
              "long": {
                "standard": "Tahiti Time"
              }
            },
            "Taipei": {
              "long": {
                "generic": "Taipei Time",
                "standard": "Taipei Standard Time",
                "daylight": "Taipei Daylight Time"
              }
            },
            "Tajikistan": {
              "long": {
                "standard": "Tajikistan Time"
              }
            },
            "Tokelau": {
              "long": {
                "standard": "Tokelau Time"
              }
            },
            "Tonga": {
              "long": {
                "generic": "Tonga Time",
                "standard": "Tonga Standard Time",
                "daylight": "Tonga Summer Time"
              }
            },
            "Truk": {
              "long": {
                "standard": "Chuuk Time"
              }
            },
            "Turkmenistan": {
              "long": {
                "generic": "Turkmenistan Time",
                "standard": "Turkmenistan Standard Time",
                "daylight": "Turkmenistan Summer Time"
              }
            },
            "Tuvalu": {
              "long": {
                "standard": "Tuvalu Time"
              }
            },
            "Uruguay": {
              "long": {
                "generic": "Uruguay Time",
                "standard": "Uruguay Standard Time",
                "daylight": "Uruguay Summer Time"
              }
            },
            "Uzbekistan": {
              "long": {
                "generic": "Uzbekistan Time",
                "standard": "Uzbekistan Standard Time",
                "daylight": "Uzbekistan Summer Time"
              }
            },
            "Vanuatu": {
              "long": {
                "generic": "Vanuatu Time",
                "standard": "Vanuatu Standard Time",
                "daylight": "Vanuatu Summer Time"
              }
            },
            "Venezuela": {
              "long": {
                "standard": "Venezuela Time"
              }
            },
            "Vladivostok": {
              "long": {
                "generic": "Vladivostok Time",
                "standard": "Vladivostok Standard Time",
                "daylight": "Vladivostok Summer Time"
              }
            },
            "Volgograd": {
              "long": {
                "generic": "Volgograd Time",
                "standard": "Volgograd Standard Time",
                "daylight": "Volgograd Summer Time"
              }
            },
            "Vostok": {
              "long": {
                "standard": "Vostok Time"
              }
            },
            "Wake": {
              "long": {
                "standard": "Wake Island Time"
              }
            },
            "Wallis": {
              "long": {
                "standard": "Wallis & Futuna Time"
              }
            },
            "Yakutsk": {
              "long": {
                "generic": "Yakutsk Time",
                "standard": "Yakutsk Standard Time",
                "daylight": "Yakutsk Summer Time"
              }
            },
            "Yekaterinburg": {
              "long": {
                "generic": "Yekaterinburg Time",
                "standard": "Yekaterinburg Standard Time",
                "daylight": "Yekaterinburg Summer Time"
              }
            }
          }
        }
      }
    }
  }
}
