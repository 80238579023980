import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserStore } from '../store/user/user.store';
import { ToastService } from '../services/toast.service';
import { TranslationService } from '../services/translation/translation.service';

export const AdminGuard: CanActivateFn = async () => {
  const userStore = inject(UserStore);
  const router = inject(Router);
  const toast = inject(ToastService);
  const translationService = inject(TranslationService);

  /**
   * Wait for the specified `timeoutDuration` to allow the store to initialize.
   */
  await new Promise(resolve => setTimeout(resolve, 200));

  if (userStore.isAdmin()) {
    return true;
  }

  toast.show(
    'Error',
    translationService.translate('Guard.AdminTitle'),
    translationService.translate('Guard.AdminDesc'),
  );
  await router.navigateByUrl('search');

  return false;
};
