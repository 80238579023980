import { Component, inject } from '@angular/core';
import { ButtonComponent } from '../../../layout/button/button.component';
import { TranslatePipe } from '../../../pipes/translation/translation.pipe';
import { PasswordChangeService } from '../../../services/security/password-change.service';

@Component({
    selector: 'hk-security-settings',
    imports: [ButtonComponent, TranslatePipe],
    templateUrl: './security-settings.component.html',
    styleUrl: './security-settings.component.scss'
})
export class SecuritySettingsComponent {
  passwordChangeService = inject(PasswordChangeService);

  redirectToPasswordChange() {
    this.passwordChangeService.redirectToPasswordChange();
  }
}
